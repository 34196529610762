import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { authenticationSlice } from './slices/authentication.slice';
import { authenticationApi } from './api/authenticationApi';
import { domainApi } from './api/domainApi';
import { backlinksApi } from './api/backlinksApi';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/es/storage';
import { domainSlice } from './slices/domain.slice';
import { backlinkSlice } from './slices/backlink.slice';
import { backlinkEflApi } from './api/backlinkEflApi';
import { backlinkReportApi } from './api/backlinkReportApi';
import { googleCheckerApi } from './api/googleCheckerApi';
import { tagApi } from './api/tagApi';
import { userApi } from './api/userApi';
import { organizationApi } from './api/organizationApi';
import { faqApi } from './api/faqApi';
import { faqCategoryApi } from './api/faqCategories';
import { productsApi } from './api/productsApi';
import { productsSlice } from './slices/products.slice';
import { domainUserRoleApi } from './api/domainUserRoleApi';
import { batchApi } from './api/backlinkBatchApi';
import { batchTypesApi } from './api/batchTypesApi';
import { nicheTypeApi } from './api/nicheTypesApi';
import { organizationBudgetTypeApi } from './api/organizationBudgetTypeApi';
import { organizationLinkBuildingStratergiesTypeApi } from './api/organizationLinkBuilidingStrategiesTypeApi';
import { organizationLinkEstimationTypeApi } from './api/organizationLinkEstimationTypeApi';
import { organizationSEOExperienceTypeApi } from './api/organizationSEOExperienceTypeApi';
import { organizationTypeApi } from './api/organizationTypeApi';
import { bugApi } from './api/bugsApi';
import { notificationsApi } from './api/notificationsApi';
import { bugSlice } from './slices/bugs.slice';
import { organizationSourceEntityTypeApi } from './api/organizationSourceTypeApi';
import { organizationNewFeatureTypeApi } from './api/organizationNewFeatureApi';
import { roleApi } from './api/roleApi';
import { featureApi } from './api/featureApi';
import { featureSlice } from './slices/feature.slice';
import { assetApi } from './api/assetApi';

const authPersistedReducer = persistReducer(
  {
    key: 'auth',
    storage,
  },
  authenticationSlice.reducer
);

const domianPersistedDomainReducer = persistReducer(
  {
    key: 'domain',
    storage,
  },
  domainSlice.reducer
);

const backlinksPersistedReducer = persistReducer(
  {
    key: 'backlinks',
    storage,
  },
  backlinkSlice.reducer
);

const productsPersistReducer = persistReducer(
  {
    key: 'products',
    storage,
  },
  productsSlice.reducer
);

const bugsPersistedReducer = persistReducer(
  {
    key: 'bugs',
    storage,
  },
  bugSlice.reducer
);

export const store = configureStore({
  reducer: {
    authentication: authPersistedReducer,
    domain: domianPersistedDomainReducer,
    backlink: backlinksPersistedReducer,
    products: productsPersistReducer,
    bugs: bugsPersistedReducer,
    feature: featureSlice.reducer,
    [authenticationApi.reducerPath]: authenticationApi.reducer,
    [domainApi.reducerPath]: domainApi.reducer,
    [backlinksApi.reducerPath]: backlinksApi.reducer,
    [googleCheckerApi.reducerPath]: googleCheckerApi.reducer,
    [backlinkEflApi.reducerPath]: backlinkEflApi.reducer,
    [backlinkReportApi.reducerPath]: backlinkReportApi.reducer,
    [tagApi.reducerPath]: tagApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [faqApi.reducerPath]: faqApi.reducer,
    [faqCategoryApi.reducerPath]: faqCategoryApi.reducer,
    [organizationApi.reducerPath]: organizationApi.reducer,
    [productsApi.reducerPath]: productsApi.reducer,
    [nicheTypeApi.reducerPath]: nicheTypeApi.reducer,
    [domainUserRoleApi.reducerPath]: domainUserRoleApi.reducer,
    [batchApi.reducerPath]: batchApi.reducer,
    [batchTypesApi.reducerPath]: batchTypesApi.reducer,
    [organizationBudgetTypeApi.reducerPath]: organizationBudgetTypeApi.reducer,
    [organizationLinkBuildingStratergiesTypeApi.reducerPath]:
      organizationLinkBuildingStratergiesTypeApi.reducer,
    [organizationLinkEstimationTypeApi.reducerPath]: organizationLinkEstimationTypeApi.reducer,
    [organizationSEOExperienceTypeApi.reducerPath]: organizationSEOExperienceTypeApi.reducer,
    [organizationTypeApi.reducerPath]: organizationTypeApi.reducer,
    [bugApi.reducerPath]: bugApi.reducer,
    [notificationsApi.reducerPath]: notificationsApi.reducer,
    [organizationSourceEntityTypeApi.reducerPath]: organizationSourceEntityTypeApi.reducer,
    [organizationNewFeatureTypeApi.reducerPath]: organizationNewFeatureTypeApi.reducer,
    [roleApi.reducerPath]: roleApi.reducer,
    [featureApi.reducerPath]: featureApi.reducer,
    [assetApi.reducerPath]: assetApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      authenticationApi.middleware,
      domainApi.middleware,
      backlinksApi.middleware,
      googleCheckerApi.middleware,
      backlinkEflApi.middleware,
      backlinkReportApi.middleware,
      tagApi.middleware,
      userApi.middleware,
      organizationApi.middleware,
      faqApi.middleware,
      faqCategoryApi.middleware,
      productsApi.middleware,
      nicheTypeApi.middleware,
      domainUserRoleApi.middleware,
      batchApi.middleware,
      batchTypesApi.middleware,
      organizationBudgetTypeApi.middleware,
      organizationLinkBuildingStratergiesTypeApi.middleware,
      organizationLinkEstimationTypeApi.middleware,
      organizationTypeApi.middleware,
      organizationSEOExperienceTypeApi.middleware,
      bugApi.middleware,
      notificationsApi.middleware,
      organizationSourceEntityTypeApi.middleware,
      organizationNewFeatureTypeApi.middleware,
      roleApi.middleware,
      featureApi.middleware,
      assetApi.middleware
    ),
});

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export type RootState = ReturnType<typeof store.getState>;
export const persistor = persistStore(store);
