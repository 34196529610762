import { ComponentStyleConfig, extendTheme, Input, MenuItem } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';

const AppColors = {
  primary: '#222222',
  secondary: '#00a4ff',
  secondary2: '#037DFC',
  tertiary: '#606060',
  contentColor: '#b1b1b1',
  textColor: '#4A4F54',
  innerContentColor: '#ffffff',
  appBackground: '#eeeeee',
  appBorder: '#EBEBEB',
  iconColorOne: '#2DD6FA',
  outlineColor: '#2DBCFA',
  ctaColor: '#FF8800',
  successHighlightColor: '#f4faf0',
  warningHighlightColor: '#fef9f6',
  errorHighlightColor: '#fbeeef',
  infoHighlightColor: '#e5f8fa',
  successColor: '#349662',
  warningColor: '#e2a73a',
  errorColor: '	#e02d3c',
  infoColor: '#485770',
};

const textStyles = {
  h1: {
    fontSize: ['56px', '36px'],
    fontWeight: '700',
  },
  h2: {
    fontSize: ['30px', '48px'],
  },
  h3: {
    fontSize: ['22px', '36px'],
    fontWeight: '700',
  },
  h4: {
    fontSize: ['20px', '24px'],
    fontWeight: '700',
  },
  h5: {
    fontSize: ['18px', '20px'],
    fontWeight: '700',
  },
  p1: {
    fontSize: '18px',
  },
  body: {
    fontSize: ['12px', '14px', '16px'],
    fontWeight: '400',
  },
};

const inputComponentStyle: ComponentStyleConfig = {
  baseStyle: {
    height: '60px',
    bgColor: 'gray.100',
    border: 0,
    color: 'gray.500',
    _placeholder: {
      color: 'gray.500',
    },
  },
  variants: {
    default: {
      field: {
        bgColor: 'white',
        border: '1px solid',
        borderColor: 'rgba(0, 0, 0, 0.05)',
        borderRadius: 'full',
        color: 'gray.500',
      },
    },
    blue: {
      field: {
        bgColor: 'rgba(45, 188, 250, 0.1)',
        border: '1px solid',
        outline: 'rgba(3, 125, 252, 0.16) !important',
        borderColor: 'rgba(3, 125, 252, 0.16) !important',
        borderRadius: 'xl',
      },
    },
    blueSquared: {
      field: {
        bgColor: 'rgba(45, 188, 250, 0.1)',
        border: '1px solid',
        outline: 'rgba(3, 125, 252, 0.16) !important',
        borderColor: 'rgba(3, 125, 252, 0.16) !important',
        borderRadius: 'xl',
      },
    },
    grayed: {
      field: {
        bgColor: 'rgba(177, 177, 177, 0.1)',
        borderRadius: '2xl',
      },
    },
    rounded: {
      field: {
        borderRadius: 'full !important',
      },
    },
  },
};

const textAreaComponentStyle: ComponentStyleConfig = {
  baseStyle: {
    minH: '120px',
    border: `1px solid ${AppColors.appBorder}`,
  },
  variants: {
    default: {
      bgColor: 'white',
      border: '1px solid',
      borderColor: 'rgba(0, 0, 0, 0.05)',
      borderRadius: '2xl',
      color: 'gray.500',
    },
    blue: {
      bgColor: 'rgba(45, 188, 250, 0.1)',
      border: '1px solid',
      outline: 'rgba(3, 125, 252, 0.16) !important',
      borderColor: 'rgba(3, 125, 252, 0.16) !important',
      borderRadius: 'xl',
    },
    blueSquared: {
      bgColor: 'rgba(45, 188, 250, 0.1)',
      border: '1px solid',
      outline: 'rgba(3, 125, 252, 0.16) !important',
      borderColor: 'rgba(3, 125, 252, 0.16) !important',
      borderRadius: 'xl',
    },
    grayed: {
      bgColor: 'rgba(177, 177, 177, 0.1) !important',
      borderRadius: '2xl',
    },
  },
};

const selectComponentStyle: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: 'xl',
    border: `1px solid ${AppColors.appBorder}`,
    height: '60px',
    _selected: {
      border: `1px solid ${AppColors.iconColorOne}`,
    },
  },
};
const buttonComponentStyle: ComponentStyleConfig = {
  baseStyle: {
    fontWeight: '400',
    color: AppColors.primary,
    borderRadius: 'full',
    border: 'none',
    cursor: 'pointer',
  },
  sizes: {
    xs: {
      fontSize: '12px',
      px: 2,
      py: 1,
    },
    sm: {
      fontSize: '12px',
    },
    md: {
      fontSize: '14px',
      px: 8,
      py: 4,
    },
    lg: {
      fontSize: '18px',
    },
    xl: {
      fontSize: '20px',
      px: 8,
      py: 4,
    },
    xxl: {
      fontSize: '26px',
      px: 8,
      py: 4,
    },
  },
  variants: {
    ghost: {
      bgColor: 'transparent !important',
      borderColor: `${AppColors.outlineColor} !important`,
      borderRadius: 'full',
      cursor: 'pointer',
      color: AppColors.outlineColor,
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'solid',
  },
};

const tooltipTheme = {
  baseStyle: {
    borderRadius: '4px',
    padding: '20px',
    backgroundColor: '#fff',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.06), 0 4px 16px 0 rgba(0, 0, 0, 0.08)',
    color: AppColors.textColor,
    fontSize: '12px',
  },
};

const checkboxTheme: ComponentStyleConfig = {
  baseStyle: {
    control: {
      '&[data-checked]': {
        background: AppColors.primary,
        borderColor: AppColors.primary,
      },
      bgColor: AppColors.appBackground,
    },
  },
};

const menuItemComponentStyleConfig: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: 'md',
    p: 2,
    mx: 1,
    _hover: {
      bgColor: AppColors.appBorder,
    },
  },
};

const theme = extendTheme({
  textStyles: textStyles,
  components: {
    Button: buttonComponentStyle,
    Tooltip: tooltipTheme,
    Checkbox: checkboxTheme,
    Input: inputComponentStyle,
    Textarea: textAreaComponentStyle,
    MenuItem: menuItemComponentStyleConfig,
  },
});

type AppTextStyles = keyof typeof textStyles;
type AppButtonVariants =
  | 'underline'
  | 'solid'
  | 'outline'
  | 'ghost'
  | 'withStartIcon'
  | 'roundedUnderline'
  | 'borderless';
type AppColorsType = keyof typeof AppColors;

export { theme, AppColors };
export type { AppTextStyles, AppButtonVariants, AppColorsType };
