import { createApi } from '@reduxjs/toolkit/query/react';
import { AppFetchBaseQuery } from './apiBase';
import { FeatureDto } from '@backlinkit/shared';

const baseUrl = '/feature';

export const featureApi = createApi({
  reducerPath: 'featureApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchFeatures: build.query<FeatureDto[], void>({
      query: () => ({
        url: baseUrl,
        method: 'GET',
      }),
      transformResponse: (response: FeatureDto[]) => {
        return response;
      },
    }),
  }),
});

export const { useFetchFeaturesQuery, useLazyFetchFeaturesQuery } = featureApi;
