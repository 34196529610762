import BaseLayout from '../../components/layouts/base-layout';
import { Text, Flex, useColorModeValue, useToast } from '@chakra-ui/react';

import {
  usePanel,
  ColumnDefinitionType,
  AppButton,
  AppDataTable,
  AppColors,
  AssetDto,
  AssetFormImage,
  useLoading,
} from '@backlinkit/shared';
import { RoleFormData } from '../../components/forms/role-form';
import { useFetchAssetsQuery, useFileUploadMutation } from '../../store/api/assetApi';
import AssetForm from '../../components/forms/asset-form';

export const AssetManagementPage: React.FC = () => {
  const { setLoading } = useLoading();
  const [uploadFile] = useFileUploadMutation();
  const panel = usePanel();
  const toast = useToast();

  const { data: assets, refetch: refetchAssets } = useFetchAssetsQuery({
    refetchOnMountOrArgChange: true,
  });

  const roleColumns: ColumnDefinitionType<AssetDto>[] = [
    {
      key: 'name',
      header: 'Name',
    },
    {
      key: 'url',
      header: 'Url',
    },
  ];

  const handleAddDialog = () => {
    const form: RoleFormData = {
      name: '',
      description: '',
      features: [],
      isPortalRole: false,
    };
    panel({
      title: 'Create Asset',
      size: 'lg',
      render: (onSubmit: any) => (
        <AssetForm
          key={`userPanelCreate`}
          onSubmit={async (formData) => {
            onSubmit();
            await addAsset(formData);
          }}
        ></AssetForm>
      ),
    });
  };

  const addAsset = async (files: AssetFormImage[]) => {
    setLoading(true);
    try {
      if (files) {
        for (const image of files) {
          await uploadFile({
            file: image.imageUrl,
            fileName: `${image.name}-${new Date().valueOf()}`,
          }).unwrap();
        }

        refetchAssets();
      }
    } catch (err) {
      toast({
        title: 'Asset saving issue',
        description: "We've ran into an issue while saving the Asset",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <BaseLayout>
      <Flex flex={1} direction={'column'}>
        <Flex m={4} justifyContent={'space-between'} align={'center'} minH={'65px'}>
          <Text fontSize={'2xl'} fontWeight={'bold'} color={AppColors.primary}>
            {'Asset Management'}
          </Text>
          <AppButton
            bg={'white'}
            size={'sm'}
            border={`1px solid ${AppColors.appBorder}`}
            borderRadius={'full'}
            onClick={handleAddDialog}
          >
            Add Asset
          </AppButton>
        </Flex>
        <Flex
          bg={useColorModeValue('white', 'gray.800')}
          rounded={'2xl'}
          overflow={'hidden'}
          padding={'4'}
          m={4}
          flexDir={'column'}
          flex={1}
        >
          <AppDataTable
            data={assets || []}
            noDataMessage="No assets added, start by adding some!"
            columns={roleColumns}
          />
        </Flex>
      </Flex>
    </BaseLayout>
  );
};
