import { Box, Flex, useToast } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import BaseLayout from '../../components/layouts/base-layout';
import {
  useEditBacklinkMutation,
  useLazyBacklinkBulkCreateQuery,
  useLazyBacklinkBulkRescrapeQuery,
  useLazyBacklinkLinkBulkDeleteQuery,
  useLazyBacklinkRescrapeQuery,
  useLazyFetchBacklinksByOptionsQuery,
} from '../../store/api/backlinksApi';
import { selectUser } from '../../store/slices/authentication.slice';
import { selectDomain, setSelectedDomain } from '../../store/slices/domain.slice';
import { useAppSelector } from '../../store/store';
import { useEffect, useState } from 'react';
import {
  resetTempBacklinkGoogleList,
  resetTempBacklinkList,
  selectTempBacklinkGoogleList,
  selectTempBacklinkList,
  setSelectedBacklink,
} from '../../store/slices/backlink.slice';
import { useNavigate } from 'react-router-dom';
import { RoutesList } from '../../router/router';
import { SettingsIcon } from '@chakra-ui/icons';
import { BsLink } from 'react-icons/bs';
import { useLazyFetchDomainByIdQuery } from '../../store/api/domainApi';
import BacklinksTable, { BacklinkMenuItems } from '../../components/backlinks/backlinks-table';
import SecondTierLinkCountCell from '../../components/backlinks/components/cells/second-tier-link-count-cell';
import { useSaveBacklinkReportMutation } from '../../store/api/backlinkReportApi';
import { addDays } from 'date-fns';
import { useLazyFetchTagsByOrganizationIdQuery } from '../../store/api/tagApi';
import {
  useLoading,
  useDialog,
  PaginatedData,
  BackLinkListItem,
  SearchParam,
  BacklinkFilter,
  Pagination,
  BacklinkFormData,
  BackLink,
  stripTrailingSlash,
  BackLinkCreate,
  BacklinkTypes,
  BacklinkBulkCreateRequest,
  BacklinkQueueModel,
  BackLinkUpdateBulkRequest,
  BacklinkReport,
  AppConfirm,
  BacklinkBulkDeleteRequest,
  ColumnDefinitionType,
  PageChangeEvent,
  SortParam,
  sortingColumnsToSortingArray,
  AppPaginator,
  BacklinkBulkScrapeItem,
  ResponseMethodTypes,
} from '@backlinkit/shared';

const Links: React.FC = () => {
  const title = 'Links';
  const { setLoading } = useLoading();
  const dialog = useDialog();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const domain = useSelector(selectDomain);
  const tempBacklinks = useSelector(selectTempBacklinkList);
  const tempGoogleData = useSelector(selectTempBacklinkGoogleList);
  const [domainBacklinks, setDomainBacklinks] = useState<PaginatedData<BackLinkListItem>>();
  const [currentPage, setCurrentPage] = useState<number>();
  const [currentPageSize, setCurrentPageSize] = useState<number>();
  const [currentSortList, setCurrentSortList] = useState<SortParam[]>([]);
  const [currentSearchParam, setCurrentSearchParam] = useState<SearchParam>();

  const [backlinkFilter, setBacklinkFilter] = useState<BacklinkFilter>();

  const [pagination, setPagination] = useState<Pagination>();

  const [getAllTagsForOrganization] = useLazyFetchTagsByOrganizationIdQuery();

  const [editBacklinkTrigger] = useEditBacklinkMutation();

  const [getScrapedBacklinkData] = useLazyBacklinkRescrapeQuery();

  const [postBacklinkBulkCreate] = useLazyBacklinkBulkCreateQuery();
  const [postBacklinkLinkBulkDelete] = useLazyBacklinkLinkBulkDeleteQuery();

  const [postBacklinkBulkUpdate] = useLazyBacklinkBulkRescrapeQuery();

  const [getDomainById] = useLazyFetchDomainByIdQuery();
  const [saveBacklinkReportTrigger] = useSaveBacklinkReportMutation();

  const [getAllBacklinks] = useLazyFetchBacklinksByOptionsQuery();

  const user = useAppSelector(selectUser);

  const toast = useToast();

  useEffect(() => {
    if (currentPage && currentPageSize) {
      setupBacklinks(
        currentPage,
        currentPageSize,
        currentSortList,
        currentSearchParam,
        backlinkFilter
      );
    }
  }, [currentPage, currentPageSize]);

  useEffect(() => {
    if (domainBacklinks && domainBacklinks.pagination) {
      console.log('dashboardDomains?.pagination ', domainBacklinks);
      setPagination(domainBacklinks?.pagination);

      setCurrentPage(domainBacklinks.pagination?.page || 1);
      setCurrentPageSize(domainBacklinks.pagination?.pageSize || 10);
    }
  }, [domainBacklinks]);

  const setupBacklinks = async (
    page: number | undefined,
    pageSize: number | undefined,
    sortList: SortParam[] | undefined,
    searchParam: SearchParam | undefined,
    backlinkFilter: BacklinkFilter | undefined
  ) => {
    console.log('setupBacklinks');
    setLoading(true);
    setDomainBacklinks(undefined);
    const backlinks = await getAllBacklinks({
      pageNumber: page,
      pageSize,
      searchParam,
      filterOptions: backlinkFilter,
    }).unwrap();

    setDomainBacklinks(backlinks);
    setLoading(false);
  };

  const addBacklink = async (backlinkFormsData: BacklinkFormData[], isNote: boolean = false) => {
    console.log(backlinkFormsData);
    const backlinksToAddCount = backlinkFormsData.length;
    const edittedBacklink: boolean =
      backlinksToAddCount === 1 && backlinkFormsData[0].id ? true : false;

    try {
      if (!user) {
        toast({
          title: 'User account not found.',
          description: 'Sign out and back in.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });

        return;
      }
      setLoading(true);

      const backlinkList: BackLink[] = [];

      if (!edittedBacklink) {
        const listToBeSaved = [];
        for (const backlinkFormData of backlinkFormsData) {
          const strippedSlashUrl = stripTrailingSlash(backlinkFormData.url);

          const foundBacklink = domain?.backlinks?.find(
            (x) => stripTrailingSlash(x.url) === strippedSlashUrl
          );

          if (foundBacklink) {
            backlinkList.push(foundBacklink);
          } else {
            const newBacklink: BackLinkCreate = {
              url: backlinkFormData.url,
              expectedLandingPage: backlinkFormData.expectedLandingPage,
              expectedAnchor: backlinkFormData.expectedAnchor,
              notes: backlinkFormData.notes,
              domainId: domain?.id || '',
              expectedRelValue: backlinkFormData.expectedRel,
              backlinkType: BacklinkTypes.tierOne,
            };
            listToBeSaved.push(newBacklink);
          }
        }

        const bulkData = {
          domainId: domain?.id,
          backlinks: listToBeSaved,
        } as BacklinkBulkCreateRequest;

        await postBacklinkBulkCreate(bulkData).unwrap();
      } else {
        const backlinkFormData = backlinkFormsData[0];
        if (backlinkFormData && backlinkFormData.id) {
          await editBacklinkTrigger({
            id: backlinkFormData.id,
            url: backlinkFormData.url,
            expectedLandingPage: backlinkFormData.expectedLandingPage,
            expectedAnchor: backlinkFormData.expectedAnchor,
            notes: backlinkFormData.notes,
            domainId: domain?.id || '',
            expectedRelValue: backlinkFormData.expectedRel,
            backlinkType: BacklinkTypes.tierOne,
          }).unwrap();
        }
      }

      if (domain) {
        const newDomain = await getDomainById(domain.id || '').unwrap();
        await dispatch(setSelectedDomain(newDomain));
      }

      setLoading(false);

      if (backlinkList.length > 0) {
        handleDuplicateUrlAddingConfirm(backlinkList);
      } else {
        toast({
          title: `Backlink ${!edittedBacklink ? 'added' : 'updated'}.`,
          description: `We've  ${
            !edittedBacklink ? 'added' : 'updated'
          }. your backlink for you, come back soon to monitor the status`,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });

        setupBacklinks(
          currentPage,
          currentPageSize,
          currentSortList,
          currentSearchParam,
          backlinkFilter
        );
      }
    } catch (error) {
      toast({
        title: `Backlink  ${!edittedBacklink ? 'add' : 'update'}. error.=`,
        description: `We've run into a problem  ${
          !edittedBacklink ? 'adding' : 'updating'
        }. your backlink, Contact support for help`,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });

      setLoading(false);
    }
  };

  const handleBackButton = () => {
    navigate(RoutesList.Dashboard);
  };

  const handleBacklinkRescrape = async (backlinkId: string) => {
    setLoading(true);

    getScrapedBacklinkData({ backlinkId, parentUrl: domain?.url || '' });
    toast({
      title: 'We are processing your backlink',
      description: 'Your data will update soon!',
      status: 'success',
      duration: 5000,
      isClosable: true,
    });

    setLoading(false);
  };

  const handleBacklinksRescrape = async (selectedBacklinks: BackLinkListItem[]) => {
    let backlinks: BacklinkBulkScrapeItem[] = [];

    selectedBacklinks.forEach((x) => {
      // if (x.backlinkMeta?.backlinkResponse?.methodType !== ResponseMethodTypes.ExternalApi) {
      backlinks.push({
        backlinkId: x.id,
        parentUrl: domain?.url,
        url: x.url,
        expectedLandingPage: x.expectedLandingPage,
        expectedAnchor: x.expectedAnchor,
        expectedRel: x.expectedRelValue,
        backlinkType: x.backlinkType,
        isManual: x.isManual,
      } as BacklinkBulkScrapeItem);
      // }
    });
    const data: BackLinkUpdateBulkRequest = {
      backlinks: backlinks,
    };

    postBacklinkBulkUpdate(data);

    toast({
      title: 'We are processing your backlink',
      description: 'Your data will update soon!',
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
  };

  const handleDeleteBacklinkClicked = (selectedBacklinks: BackLinkListItem[]) => {
    dialog({
      title: 'Delete',
      render: (onSubmit, onCancel) => {
        return (
          <AppConfirm
            message={`Are you sure you want to delete the ${selectedBacklinks.length} selected Backlinks?`}
            onConfirm={async () => {
              onSubmit();

              await handleDeleteBacklinks(selectedBacklinks);
            }}
            onCancel={() => {
              onCancel();
            }}
          ></AppConfirm>
        );
      },
    });
  };

  const handleDuplicateUrlAddingConfirm = (backlinks: BackLink[]) => {
    dialog({
      title: 'We found duplicate backlinks!',
      render: (onSubmit, onCancel) => {
        return (
          <AppConfirm
            message={`Would you like us to reprocess ${backlinks.length} backlinks, this will allow us to update your backlink with the latest data & you will be able to see the differences when using the "New Link Differences" alert`}
            onConfirm={async () => {
              onSubmit();

              setLoading(true);

              const items = backlinks.map((x) => {
                return {
                  backlinkId: x.id,
                  parentUrl: domain?.url,
                  url: x.url,
                  expectedLandingPage: x.expectedLandingPage,
                  expectedAnchor: x.expectedAnchor,
                  expectedRel: x.expectedRelValue,
                  backlinkType: x.backlinkType,
                  isManual: x.isManual,
                } as BacklinkBulkScrapeItem;
              });
              const data: BackLinkUpdateBulkRequest = {
                backlinks: items,
              };

              postBacklinkBulkUpdate(data);

              // for (const backlink of backlinks) {
              //   getScrapedBacklinkData(backlink.id);
              // }

              setLoading(false);

              // setupBacklinks();
            }}
            onCancel={() => {
              onCancel();
            }}
          ></AppConfirm>
        );
      },
    });
  };

  const handleDeleteBacklinks = async (backlinks: BackLink[]) => {
    try {
      setLoading(true);

      const backlinkUrls = backlinks.map((x) => x.id);

      const data = {
        backlinks: backlinkUrls,
      } as BacklinkBulkDeleteRequest;

      await postBacklinkLinkBulkDelete(data);
    } catch (e) {
      toast({
        title: 'Backlink Delete error.',
        description:
          "We've run into a problem deleting the selected backlinks, Contact support for help",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });

      setLoading(false);
    }

    toast({
      title: 'Selected Backlinks deleted.',
      description: "We've deleted the backlinks you selected",
      status: 'success',
      duration: 9000,
      isClosable: true,
    });

    setCurrentPage(1);

    if (domain) {
      const newDomain = await getDomainById(domain.id).unwrap();
      await dispatch(setSelectedDomain(newDomain));
    }

    setLoading(false);

    setupBacklinks(1, currentPageSize, currentSortList, currentSearchParam, backlinkFilter);
  };

  const handleBacklinkAction = async (row: BackLinkListItem) => {
    if (!row?.backlinkMeta?.linkStatus || !row?.backlinkGoogle?.lastGoogleCheckDate) {
      dialog({
        title: 'Still processing',
        render: (onSubmit, onCancel) => {
          return (
            <AppConfirm
              message={`We are still busy processing your backlink, please use this feature once the link has been processed.`}
              onConfirm={async () => {
                onSubmit();
              }}
              onCancel={() => {
                onCancel();
              }}
            ></AppConfirm>
          );
        },
      });
    } else {
      await dispatch(setSelectedBacklink(row));
    }
  };

  const handleSortByColumnClick = (columns: ColumnDefinitionType<BackLinkListItem>[]) => {
    const columnSortQueryString = sortingColumnsToSortingArray(columns);
    setCurrentSortList(columnSortQueryString);

    setupBacklinks(
      currentPage,
      currentPageSize,
      columnSortQueryString,
      currentSearchParam,
      backlinkFilter
    );
  };

  const handleOnPageChange = (pageChangeEvent: PageChangeEvent) => {
    console.log('handleOnPageChange HIT');
    setCurrentPage(pageChangeEvent.currentPage);
    setCurrentPageSize(pageChangeEvent.currentPageSize);
  };

  const handleSearchChange = (newSearchParam: SearchParam) => {
    setCurrentSearchParam(newSearchParam);
    setupBacklinks(currentPage, currentPageSize, currentSortList, newSearchParam, backlinkFilter);
  };

  const handleFiltersChange = (filter: BacklinkFilter) => {
    setBacklinkFilter(filter);
    setupBacklinks(currentPage, currentPageSize, currentSortList, currentSearchParam, filter);
  };

  const menuItems: BacklinkMenuItems[] = [
    {
      icon: <SettingsIcon />,
      onClick: (item) => handleBacklinkRescrape(item.id),
      label: 'Reprocess-link',
    },
    {
      icon: <BsLink />,
      onClick: (item) => handleBacklinkAction(item),
      label: 'T2 Backlinkit',
    },
  ];

  const additionalBackLinkColumns: ColumnDefinitionType<BackLinkListItem>[] = [
    {
      key: 'secondTierLinkCount',
      header: 'Backlinks',
      headerMeta: 'Tier 2 links nested under the tier 1 link.',
      headerSortable: false,
      render: (item) => <SecondTierLinkCountCell item={item} />,
    },
  ];

  const refreshBacklinks = async () => {
    setLoading(true);
    dispatch(resetTempBacklinkList());
    dispatch(resetTempBacklinkGoogleList());
    await setupBacklinks(
      currentPage,
      currentPageSize,
      currentSortList,
      currentSearchParam,
      backlinkFilter
    );
    setLoading(false);
  };

  return (
    <BaseLayout>
      <Box w={'100%'} display={'flex'} flexDir={'column'}>
        <Flex p={4} flex={1} direction="column">
          <BacklinksTable
            title={title}
            parentUrl={domain?.url || ''}
            data={domainBacklinks?.data || []}
            tempBacklinkCount={tempBacklinks && tempBacklinks.length}
            tempGoogleDataCount={tempGoogleData && tempGoogleData.length}
            additionalBacklinkColumns={additionalBackLinkColumns}
            additionalMenuItems={menuItems}
            onAddClick={addBacklink}
            onSearchChange={handleSearchChange}
            onSortByColumnClick={handleSortByColumnClick}
            onFiltersChange={handleFiltersChange}
            onSelectedBacklinksRescrape={handleBacklinksRescrape}
            onSelectedBacklinksDelete={handleDeleteBacklinkClicked}
            onBackButtonClick={handleBackButton}
            refetch={() => refreshBacklinks()}
          >
            <AppPaginator
              pagination={pagination}
              onPageChange={(event: PageChangeEvent) => handleOnPageChange(event)}
            ></AppPaginator>
          </BacklinksTable>
        </Flex>
      </Box>
    </BaseLayout>
  );
};

export default Links;
