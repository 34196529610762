import BaseLayout from '../../components/layouts/base-layout';
import React from 'react';
import {
  Flex,
  Button,
  Text,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
} from '@chakra-ui/react';
import {
  useFetchAllProductsQuery,
  useCreateProductMutation,
  useEditProductMutation,
  useDeleteProductMutation,
} from '../../store/api/productsApi';
import { ChevronDownIcon, DeleteIcon, SettingsIcon } from '@chakra-ui/icons';
import ProductsForm from '../../components/forms/products-form';
import PlanCell from '../../components/products-components/plan-cell';
import {
  ProductDto,
  ProductEditForm,
  ProductForm,
  AppColors,
  ColumnDefinitionType,
  ColumnSpecialTypes,
  AppButton,
  AppDataTable,
  AppText,
  usePanel,
  useLoading,
  AppConfirm,
} from '@backlinkit/shared';

const Products: React.FC = () => {
  const { data: products, refetch: refetchProducts } = useFetchAllProductsQuery({
    refetchOnMountArgChange: true,
  });
  const [saveProductTrigger] = useCreateProductMutation();
  const [editProductTrigger] = useEditProductMutation();
  const [deleteProductTrigger] = useDeleteProductMutation();
  const title = 'Products';
  const toast = useToast();
  const panel = usePanel();
  const { setLoading } = useLoading();

  const handleCreate = () => {
    return panel({
      title: 'Add Product',
      size: 'lg',
      render: (onSubmit) => {
        return (
          <ProductsForm
            onSubmit={async (formData) => {
              onSubmit();
              upsertProduct(formData);
            }}
          />
        );
      },
    });
  };

  const handleEdit = (product: ProductDto) => {
    const productForm: ProductEditForm = {
      id: product.id,
      name: product.name,
      description: product.description,
      price: product.price,
      isInitial: product.isInitial,
      type: product.type,
      linkLimit: product.linkLimit,
      freeCredits: product.linkLimit,
      priceKey: product.priceKey,
      domainLimit: product.domainLimit,
      userLimit: product.userLimit,
    };
    panel({
      title: 'Update Product',
      size: 'lg',
      render: (onSubmit) => {
        return (
          <ProductsForm
            form={productForm}
            onSubmit={async (formData) => {
              onSubmit();
              await upsertProduct(formData);
            }}
          />
        );
      },
    });
  };

  const upsertProduct = async (formData: any) => {
    setLoading(true);
    let eddittedProduct = formData.id ? true : false;
    try {
      if (!eddittedProduct) {
        const createFormData = formData as ProductForm;
        await saveProductTrigger({
          name: createFormData.name,
          description: createFormData.description,
          price: createFormData.price,
          isInitial: createFormData.isInitial,
          type: createFormData.type,
          linkLimit: createFormData.linkLimit,
          freeCredits: createFormData.freeCredits,
          priceKey: createFormData.priceKey,
          domainLimit: createFormData.domainLimit,
          userLimit: createFormData.userLimit,
        }).unwrap();
      } else {
        const editFormData = formData as ProductEditForm;
        await editProductTrigger({
          id: editFormData.id ?? '',
          name: editFormData.name,
          description: editFormData.description,
          price: editFormData.price,
          isInitial: editFormData.isInitial,
          type: editFormData.type,
          linkLimit: editFormData.linkLimit,
          freeCredits: editFormData.freeCredits,
          priceKey: editFormData.priceKey,
          domainLimit: editFormData.domainLimit,
          userLimit: editFormData.userLimit,
        }).unwrap();
        eddittedProduct = true;
      }
      refetchProducts();
      toast({
        title: `Product ${!eddittedProduct ? 'added' : 'updated'}`,
        description: `We've ${
          !eddittedProduct ? 'added' : 'updated'
        } your products for you, come back soon to check the status`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } catch (e) {
      toast({
        title: `Category ${!eddittedProduct ? 'adding' : 'updating'} error = ${e}`,
        description: `We've run into a problem ${
          !eddittedProduct ? 'adding' : 'updating'
        } your product, Contact support for help`,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
    refetchProducts();
    setLoading(false);
  };

  const handleDelete = async (item: ProductDto) => {
    setLoading(true);
    try {
      const deletePromise = deleteProductTrigger(item.id).unwrap();
      await deletePromise;
    } catch (e) {
      toast({
        title: 'Product Delete Error',
        status: 'error',
        description: `We've run into a problem deleting the selected product. Contact support for help`,
        duration: 9000,
        isClosable: true,
      });
    }
    toast({
      title: 'Product deleted',
      description: "We've deleted the product you selected",
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    refetchProducts();
    setLoading(false);
  };

  const handleDeleteConfirm = (item: ProductDto) => {
    panel({
      title: 'Delete Product',
      size: 'lg',
      render: (onSubmit, onCancel) => {
        return (
          <AppConfirm
            message="Are you sure you want to delete the product you selected?"
            onConfirm={async () => {
              onSubmit();
              await handleDelete(item);
            }}
            onCancel={() => {
              onCancel();
            }}
          />
        );
      },
    });
  };

  const renderDefaultActions = (item: ProductDto) => {
    return (
      <Menu>
        <MenuButton
          color={AppColors.primary}
          border={`1px solid ${AppColors.appBorder}`}
          size="xs"
          bgColor={'white'}
          as={Button}
          rightIcon={<ChevronDownIcon />}
          borderRadius="full"
          _hover={{
            transform: 'translateY(-2px)',
            boxShadow: 'lg',
          }}
        >
          Actions
        </MenuButton>
        <MenuList>
          <MenuItem
            icon={<SettingsIcon />}
            onClick={() => {
              handleEdit(item);
            }}
          >
            Edit
          </MenuItem>
          <MenuItem
            icon={<DeleteIcon />}
            onClick={() => {
              handleDeleteConfirm(item);
            }}
          >
            Delete
          </MenuItem>
        </MenuList>
      </Menu>
    );
  };

  const productsColumns: ColumnDefinitionType<ProductDto>[] = [
    {
      key: 'dateCreated',
      type: ColumnSpecialTypes.date,
      header: 'Date Added:',
      columnSearchable: false,
      headerSortable: true,
    },
    {
      key: 'name',
      header: 'Name:',
      columnSearchable: false,
      headerSortable: false,
    },
    {
      key: 'description',
      header: 'Details',
      columnSearchable: false,
      headerSortable: false,
    },
    {
      key: 'price',
      header: 'Price',
      headerSortable: true,
      columnSearchable: true,
      render: (item) => {
        return <AppText>{`$ ${item.price}`}</AppText>;
      },
    },
    {
      key: 'linkLimit',
      header: 'Backlinks',
    },
    {
      key: 'type',
      header: 'Plan Type:',
      render: (item) => {
        return <PlanCell item={item} />;
      },
    },
    {
      key: 'custom',
      header: 'Actions',
      render: renderDefaultActions,
    },
  ];
  return (
    <BaseLayout>
      <Flex flex={1} direction={'column'} w={'full'}>
        <Flex m={4} justify={'space-between'} align={'center'} minH={'65px'}>
          <Text fontSize={'2xl'} fontWeight={'bold'} color={AppColors.primary}>
            {title}
          </Text>
          <AppButton
            bgColor={'white'}
            border={`1px solid ${AppColors.appBorder}`}
            borderRadius={'full'}
            onClick={handleCreate}
            size={'sm'}
          >
            Add Product
          </AppButton>
        </Flex>
        <Flex
          bg={useColorModeValue('white', 'gray.800')}
          justify={'space-evenly'}
          rounded={'2xl'}
          overflow={'hidden'}
          padding={4}
          m={4}
          flexDir={'column'}
          flex={1}
        >
          <AppDataTable
            data={products || []}
            noDataMessage="No Products yet, start by adding some!"
            columns={productsColumns}
          />
        </Flex>
      </Flex>
    </BaseLayout>
  );
};
export default Products;
