import { createApi } from '@reduxjs/toolkit/query/react';
import { AppFetchBaseQuery } from './apiBase';
import { RoleDto, RoleUpsertRequest } from '@backlinkit/shared';

const baseUrl = '/role';

export const roleApi = createApi({
  reducerPath: 'roleApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchRoles: build.query<RoleDto[], any>({
      query: () => ({
        url: baseUrl,
        method: 'GET',
      }),
      transformResponse: (response: RoleDto[]) => {
        return response;
      },
    }),
    roleCreate: build.mutation<RoleDto, RoleUpsertRequest>({
      query: (body) => ({
        url: `${baseUrl}/create`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: RoleDto) => {
        return response;
      },
    }),
    roleEdit: build.mutation<RoleDto, RoleUpsertRequest>({
      query: (body) => ({
        url: `${baseUrl}/update/${body.id}`,
        method: 'PUT',
        body,
      }),
      transformResponse: (response: RoleDto) => {
        return response;
      },
    }),
    roleDelete: build.mutation<any, string>({
      query: (roleId) => ({
        url: `${baseUrl}/delete/${roleId}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useFetchRolesQuery,
  useLazyFetchRolesQuery,
  useRoleCreateMutation,
  useRoleEditMutation,
  useRoleDeleteMutation,
} = roleApi;
