import { ProductDto, AppText, ProductType } from '@backlinkit/shared';
import { Flex } from '@chakra-ui/react';

type PlanCellProps = {
  item: ProductDto;
};

const PlanCell: React.FC<PlanCellProps> = ({ item }) => {
  const getPlanType = () => {
    switch (item.type) {
      case ProductType.CHECKERCREDITS:
        return 'Checker Credits';
      case ProductType.SUBSCRIPTION:
        return 'Subscription';
      case ProductType.PAYG:
        return 'Pay As You Go';
    }
  };

  return (
    <Flex>
      <AppText fontSize={'sm'}>{getPlanType()}</AppText>
    </Flex>
  );
};

export default PlanCell;
