import { NavItem } from '@backlinkit/shared';

export const HeaderNavItems: Array<NavItem> = [
  {
    label: 'Dashboard',
    href: '/dashboard',
  },
  {
    label: 'Users',
    href: '/users',
  },
  {
    label: 'Domains',
    href: '/domains',
  },
  {
    label: 'Organizations',
    href: '/organizations',
  },
  {
    label: 'Organization Types',
    href: '/organization-types',
  },
  {
    label: 'Organization Budget Types',
    href: '/organization-budget-types',
  },
  {
    label: 'Organization SEO Experience Types',
    href: '/organization-seo-experience-types',
  },
  {
    label: 'Organization Link Experience Types',
    href: '/organization-link-experience-types',
  },
  {
    label: 'Organization Link Building Strategies Types',
    href: '/organization-link-building-strategies-types',
  },
  {
    label: 'Organization Source Types',
    href: '/organization-source-entity-types',
  },
  {
    label: 'Organization New Feature Types',
    href: '/organization-new-feature-types',
  },
  {
    label: 'Links',
    href: '/links',
  },
  {
    label: 'Link Reports',
    href: '/links/reports',
  },
  {
    label: "FAQ's",
    href: '/faq',
  },
  {
    label: 'FAQ Categories',
    href: '/faq-categories',
  },
  {
    label: 'Products',
    href: '/products',
  },
  {
    label: 'Niche Types',
    href: '/niche-types',
  },
  {
    label: 'Domain User Roles',
    href: '/domain/user-roles',
  },
  {
    label: 'Batch Types',
    href: '/batches/types',
  },
  {
    label: 'Bugs',
    href: '/bugs',
  },
  {
    label: 'Notifications',
    href: '/notifications',
  },
  {
    label: 'Roles',
    href: '/roles',
  },
  {
    label: 'Assets',
    href: '/assets',
  },
];
