import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  FormLabel,
  Input,
  Select,
  Table,
  TableContainer,
  Tag,
  TagLabel,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { BaseFormProps } from './base-forms';
import {
  AppInput,
  AppButton,
  UserCreateForm,
  AppColors,
  Organization,
  AppText,
  SelectOption,
  SelectListFilter,
} from '@backlinkit/shared';
import { SearchIcon } from '@chakra-ui/icons';
import { useFetchAllOrganizationsQuery } from '../../store/api/organizationApi';
import { useFetchRolesQuery } from '../../store/api/roleApi';

export interface UserCreateFormData {
  email: string;
  password: string;
  roleId: string;
  organizationId: string;
}

export const UserCreateFormDataDefaultValues: UserCreateFormData = {
  email: '',
  password: '',
  organizationId: '',
  roleId: '',
};

const UserCreateFormDataSchema = yup.object({
  email: yup.string().email().required('Field is required'),
  password: yup.string().required('Field is required'),
  roleId: yup.string().required('Field is required'),
  organizationId: yup.string(),
});

type UserCreateFormProps<T> = {
  form?: UserCreateForm;
} & BaseFormProps<T>;

const UsersForm: React.FC<UserCreateFormProps<UserCreateForm>> = ({ form, onSubmit }) => {
  const [roleList, setRoleList] = useState<SelectOption[]>([]);
  const { data: roles } = useFetchRolesQuery({});

  useEffect(() => {
    if (roles) {
      const selectors: SelectOption[] = roles.map((x) => {
        return {
          label: x.name,
          value: x.id,
        };
      });
      setRoleList(selectors);
    }
  }, [roles]);

  const {
    control: UserCreateControl,
    formState: { isValid, errors },
    setValue,
    handleSubmit,
  } = useForm<UserCreateFormData>({
    defaultValues: form || UserCreateFormDataDefaultValues,
    resolver: yupResolver(UserCreateFormDataSchema),
    mode: 'onChange',
  });

  const { data: organizations, refetch: refetchOrganizations } = useFetchAllOrganizationsQuery({
    refetchOnMountArgChange: true,
  });

  const [searchParam, setSearchParam] = useState<string>('');
  const [organizationSearchResponse, setOrganizationSearchResponse] = useState<Organization[]>([]);
  const [selectedOrganization, setSelectedOrganization] = useState<Organization>();

  const postSearch = async (name: string) => {
    if (!name) setOrganizationSearchResponse(organizations!);

    const lowerCaseSearchParam = name.toLowerCase();
    if (!organizations) return;
    const foundItems = organizations.filter((organization) => {
      return organization.name.toLowerCase().includes(lowerCaseSearchParam);
    });
    setOrganizationSearchResponse(foundItems);
  };

  return (
    <Flex dir="column" p={4} w={'full'}>
      <form
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 10,
          width: '100%',
        }}
      >
        <Flex direction={'column'} w={'full'} gap={3}>
          <AppText fontWeight={'500'}>Search Organizations:</AppText>
          <Flex w={'full'} gap={3}>
            <Input
              w={'full'}
              placeholder="Type Here"
              onChange={(e) => {
                e.preventDefault();
                setSearchParam(e.currentTarget.value);
              }}
            />
            <AppButton
              rightIcon={<SearchIcon />}
              bgColor={AppColors.iconColorOne}
              color={'white'}
              onClick={() => {
                postSearch(searchParam);
              }}
            >
              Seach
            </AppButton>
          </Flex>
          {organizationSearchResponse && organizationSearchResponse.length > 0 && (
            <Box maxH={'200px'} overflowY={'scroll'}>
              <TableContainer>
                <Table variant={'simple'}>
                  <Thead>
                    <Tr>
                      <Th>Name</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {organizationSearchResponse.map((selectedOrg, index) => {
                      return (
                        <Tr key={index}>
                          <Td>
                            <AppText>{selectedOrg.name}</AppText>
                          </Td>
                          <Td>
                            <AppButton
                              bgColor={AppColors.iconColorOne}
                              color={'white'}
                              variant={'solid'}
                              size={'xs'}
                              borderRadius={'full'}
                              onClick={() => {
                                setSelectedOrganization(selectedOrg);
                                setValue('organizationId', selectedOrg.id);
                              }}
                            >
                              Select
                            </AppButton>
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>
          )}
          {selectedOrganization && (
            <Flex w={'full'} align={'center'} justify={'start'}>
              <AppText>Selected Organziation:</AppText>
              <Tag borderRadius={'full'} colorScheme="grey" alignItems={'center'}>
                <TagLabel>{selectedOrganization.name}</TagLabel>
              </Tag>
            </Flex>
          )}
        </Flex>

        <AppInput<UserCreateFormData>
          control={UserCreateControl}
          name="email"
          label="email"
          placeHolder="email"
          error={errors.email}
        />
        <AppInput<UserCreateFormData>
          control={UserCreateControl}
          name="password"
          label="Password"
          placeHolder="Password"
          error={errors.password}
        />
        <Box flex={1}>
          <FormLabel mb={2} p={0}>
            Role
          </FormLabel>
          <SelectListFilter
            name="role"
            options={roleList}
            isInModal
            onSelectionChange={(items: SelectOption[]) => {
              if (items) {
                setValue('roleId', items[0]?.value, { shouldValidate: true });
              }
            }}
            w={'100%'}
          />
        </Box>

        <AppButton w={'full'} mt={8} disabled={!isValid} onClick={handleSubmit(onSubmit)}>
          Save
        </AppButton>
      </form>
    </Flex>
  );
};

export default UsersForm;
