import React from 'react';
import { Flex, Tooltip } from '@chakra-ui/react';
import { User, AppText } from '@backlinkit/shared';

type RoleCellProps = {
  item: User;
} & {};

const RoleCell: React.FC<RoleCellProps> = ({ item }) => {
  const organizationRole = item.role?.name ?? '';
  // const roleName = organizationRole === 0 ? 'Admin' : 'Backlinker';

  return (
    <Flex>
      <Tooltip>
        <AppText>{organizationRole}</AppText>
      </Tooltip>
    </Flex>
  );
};
export default RoleCell;
