import React, { useState, ChangeEvent } from 'react';
import { Stack, Image, Grid, GridItem, FormLabel } from '@chakra-ui/react';
import {
  AppColors,
  AppButton,
  AssetFormImage,
  AppImageUploaderRaw,
  getCompressedImage,
} from '@backlinkit/shared';

type AssetFormProps = {
  onSubmit: (data: AssetFormImage[]) => void;
};

const AssetForm: React.FC<AssetFormProps> = ({ onSubmit }) => {
  const [files, setFiles] = useState<AssetFormImage[] | null>(null);

  const getBase64 = async (file: File): Promise<string> => {
    const compressedFile = await getCompressedImage(file);

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(compressedFile);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const currentFiles = Array.from(e.target.files!);
    const newFilesPromises = currentFiles.map(async (file) => {
      const base64String = await getBase64(file);
      const splitString = base64String.split('base64,')[1];
      const image: AssetFormImage = {
        name: file.name,
        imageUrl: splitString,
        rawImage: base64String,
      };
      return image;
    });
    const copyFiles: AssetFormImage[] = JSON.parse(JSON.stringify(files ?? []));
    const newFiles: AssetFormImage[] = await Promise.all(newFilesPromises);
    copyFiles.push(...newFiles);
    setFiles(copyFiles);
  };

  const handleImageRemove = (index: number) => {
    const copyFiles: AssetFormImage[] = JSON.parse(JSON.stringify(files));
    copyFiles.splice(index, 1);
    setFiles(copyFiles);
  };

  const handleAssetFormSave = () => {
    onSubmit(files ?? []);
  };

  return (
    <Stack spacing={3}>
      <FormLabel>Images</FormLabel>
      <AppImageUploaderRaw
        name="images"
        error={undefined}
        inputType="file"
        onChange={handleChange}
      />
      <Grid gridTemplateColumns={'repeat(2, 1fr)'} gridColumnGap={2} gridRowGap={2} w={'full'}>
        {files?.map((image: AssetFormImage, index: number) => {
          return (
            <GridItem key={index}>
              <Image src={image.rawImage ? image.rawImage : image.imageUrl} borderRadius={'md'} />
              <AppButton
                bgColor={AppColors.primary}
                color={'white'}
                onClick={() => handleImageRemove(index)}
                size={'sm'}
                w={'100%'}
                fontSize={'12px'}
              >
                Remove Image
              </AppButton>
            </GridItem>
          );
        })}
      </Grid>

      <AppButton bgColor={AppColors.primary} color={'white'} onClick={handleAssetFormSave}>
        Save
      </AppButton>
    </Stack>
  );
};

export default AssetForm;
