import React, { useEffect, useState } from 'react';
import {
  Flex,
  PopoverProps,
  Checkbox,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  useDisclosure,
  Tooltip,
  Box,
  Button,
} from '@chakra-ui/react';
import { CgOptions } from 'react-icons/cg';
import { AppButton } from '../../app-button/app-button';
import { AppColors } from '../../../theme';
import { AppText } from '../../app-text/app-text';
import { ColumnDefinitionType } from '../app-data-table-types';
import { IoOptionsOutline } from 'react-icons/io5';

export type ColumnSearchSelectPopoverProps<T extends {}> = {
  columns: Array<ColumnDefinitionType<T>>;
  filteredSearchColumns: Array<ColumnDefinitionType<T>>;
  onSubmit?: (columns: Array<ColumnDefinitionType<T>>) => void;
} & PopoverProps;

export const ColumnSearchSelectPopover = <T extends {}>({
  columns,
  filteredSearchColumns,
  onSubmit,
  ...props
}: ColumnSearchSelectPopoverProps<T>) => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [filteredColumns, setFilteredColumns] = useState<Array<ColumnDefinitionType<T>>>([]);
  const [defaultFilteredColumns, setDefaultFilteredColumns] = useState<
    Array<ColumnDefinitionType<T>>
  >([]);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    if (columns) {
      setDefaultFilteredColumns(columns);
    }
  }, [columns]);

  useEffect(() => {
    if (filteredSearchColumns) {
      setFilteredColumns(filteredSearchColumns);
    }
  }, [filteredSearchColumns]);

  const onColumnsSelectChange = (checked: boolean, column: ColumnDefinitionType<T>) => {
    const newFilteredColumns = filteredColumns ? [...filteredColumns] : [];
    if (!newFilteredColumns.some((x) => x.key === column.key) && checked) {
      newFilteredColumns.push(column);
    } else {
      newFilteredColumns.splice(
        newFilteredColumns.findIndex((x) => x.key === column.key),
        1
      );
    }

    if (newFilteredColumns.length === 0) {
      setError(true);
    } else {
      setError(false);
    }

    setFilteredColumns(newFilteredColumns);
  };

  const onFilterSubmit = () => {
    if (filteredColumns.length === 0) {
      setError(true);
    } else {
      setError(false);

      onSubmit && onSubmit(filteredColumns);
      onClose();
    }
  };

  const isInFilteredList = (col: ColumnDefinitionType<T>) => {
    const isIn = filteredColumns.some((x) => x.key === col.key);
    return isIn;
  };

  const onPopoverClose = () => {
    onClose();
  };

  const onPopOverOpen = () => {
    if (filteredSearchColumns.length) {
      setFilteredColumns(filteredSearchColumns);
    } else {
      setFilteredColumns(columns);
    }
  };

  return (
    <Popover
      placement="bottom-end"
      onClose={onPopoverClose}
      isOpen={isOpen}
      closeOnBlur={false}
      onOpen={onPopOverOpen}
    >
      <Tooltip aria-label="tooltip" placement="bottom" label="Column search options">
        <Box ml="1">
          <PopoverTrigger>
            <IconButton
              bg={'rgba(45, 188, 250, 0.1)'}
              aria-label="column visibility button"
              isRound={false}
              onClick={onToggle}
              height={'55px'}
              w={'55px'}
              borderRadius={'xl'}
              _hover={{
                transform: 'translateY(-2px)',
                boxShadow: 'lg',
              }}
            >
              <Icon
                as={IoOptionsOutline}
                boxSize={'1em'}
                h={'30px'}
                w={'30px'}
                color={AppColors.secondary2}
              />
            </IconButton>
          </PopoverTrigger>
        </Box>
      </Tooltip>
      <Portal>
        <PopoverContent borderRadius={'xl'}>
          <PopoverArrow />
          <PopoverHeader bgColor={AppColors.appBackground} borderTopRadius={'xl'}>Columns to search on</PopoverHeader>
          <PopoverCloseButton />
          <PopoverBody>
            <Flex flexDir={'column'} p={2} gridGap={1} gap={3} py={6}>
              {defaultFilteredColumns.length &&
                defaultFilteredColumns.map((col, index) => {
                  return (
                    <Checkbox
                      key={`select-col-visible-${col.key.toString()}-${index}`}
                      value={`${col.key.toString()}`}
                      isChecked={isInFilteredList(col)}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        onColumnsSelectChange(event.currentTarget.checked, col);
                      }}
                      colorScheme='gray'
                    >
                      {col.header}
                    </Checkbox>
                  );
                })}
            </Flex>
            {error && (
              <Flex>
                <AppText variant={'body'} color={'red'}>
                  Atleast one column must be selected
                </AppText>
              </Flex>
            )}
          </PopoverBody>
          <PopoverFooter display={'flex'} justifyContent={'space-between'}>
            <Button
              isDisabled={error}
              minW={'100px'}
              borderRadius={'2xl'}
              color={AppColors.primary}
              onClick={() => {
                onFilterSubmit();
              }}
              bgColor={'white'}
            >
              <AppText fontWeight={'400'}>Ok</AppText>
            </Button>
            <Button
              minW={'100px'}
              bgColor={'rgb(255, 0, 0, 0.1)'}
              color={'red'}
              onClick={onPopoverClose}
              borderRadius={'2xl'}
            >
              <AppText fontWeight={'400'}>Cancel</AppText>
            </Button>
          </PopoverFooter>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
