import { Flex, Box, Stack, Image, useColorModeValue, useToast } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RoutesList } from '../../router/router';
import { useUserAuthenticationMutation } from '../../store/api/authenticationApi';
import { setUserAuth, logout } from '../../store/slices/authentication.slice';
import Logo from '../../assets/backlinkit_logo.svg';
import LoginForm, { LoginFormData } from '../../components/forms/login-form';
import { HttpError, AppColors, AppText } from '@backlinkit/shared';

const Login: React.FC = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const [userAuthentication, { data, isLoading, error, isSuccess, isError }] =
    useUserAuthenticationMutation();

  const dispatch = useDispatch();

  const signIn = async (loginFormData: LoginFormData) => {
    try {
      await dispatch(logout());

      const res = await userAuthentication({
        username: loginFormData.username,
        password: loginFormData.password,
      }).unwrap();
      if (res.user.role?.name === 'System Admin') {
        dispatch(setUserAuth(res));
        navigate(RoutesList.Dashboard);
      } else {
        toast({
          title: 'Unauthorized Login attempt',
          description: 'You do not have access to this portal',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (errResponse) {
      console.error(errResponse);
      const httpError: HttpError = (errResponse as any).data as HttpError;

      toast({
        title: 'Login Failed',
        description: httpError?.error?.message || 'Login failed.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex
      h={'100vh'}
      align={'center'}
      justify={'center'}
      m={'33px'}
      border={`1px solid ${AppColors.appBorder}`}
      borderRadius={'15px'}
    >
      <Stack
        spacing={8}
        mx={'auto'}
        maxW={'lg'}
        py={12}
        px={6}
        boxShadow={'lg'}
        borderRadius={'15px'}
      >
        <Stack align={'center'}>
          <Image src={Logo} alt="" objectFit={'cover'} />
        </Stack>
        <AppText variant={'h3'} textAlign={'center'}>
          Admin Portal
        </AppText>
        <Box
          w={{ md: '380px' }}
          bg={useColorModeValue('white', 'gray.800')}
          rounded={'md'}
          overflow={'hidden'}
          padding={'4'}
          p={8}
        >
          <LoginForm isLoading={isLoading} onSubmit={signIn}></LoginForm>
        </Box>
      </Stack>
    </Flex>
  );
};

export default Login;
