import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  Box,
  Checkbox,
  Flex,
  FormLabel,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { BaseFormProps } from './base-forms';
import { AppButton, AppInput, FeatureSelect } from '@backlinkit/shared';

export interface RoleFormData {
  name: string;
  description: string;
  features: FeatureSelect[];
  isPortalRole: boolean;
}

export const UserCreateFormDataDefaultValues: RoleFormData = {
  name: '',
  description: '',
  features: [],
  isPortalRole: false,
};

const UserCreateFormDataSchema = yup.object({
  name: yup.string().required('Field is required'),
  description: yup.string().max(110).required('Field is required'),
  isPortalRole: yup.boolean(),
});

type RoleFormProps<T> = {
  form?: RoleFormData;
  featureList: FeatureSelect[];
} & BaseFormProps<T>;

const RoleForm: React.FC<RoleFormProps<RoleFormData>> = ({ form, featureList, onSubmit }) => {
  const {
    control: CreateControl,
    formState: { isValid, errors },
    getValues,
    setValue,
  } = useForm<RoleFormData>({
    defaultValues: form || UserCreateFormDataDefaultValues,
    resolver: yupResolver(UserCreateFormDataSchema),
    mode: 'onChange',
  });

  const [features, setFeatures] = useState<FeatureSelect[]>([]);

  useEffect(() => {
    if (featureList) {
      setFeatures(featureList);
    }
  }, [featureList]);

  const changeFeature = (featureId: string, propKey: 'read' | 'write', isSelected: boolean) => {
    const featuresCopy: FeatureSelect[] = JSON.parse(JSON.stringify(features));

    featuresCopy.forEach((feature) => {
      if (feature.id === featureId) {
        feature[propKey] = isSelected;
      }
    });

    setFeatures(featuresCopy);
  };

  const submitForm = () => {
    const formValues = getValues();
    onSubmit({
      name: formValues.name,
      description: formValues.description,
      features: features,
      isPortalRole: formValues.isPortalRole,
    });
  };

  const renderRoleCard = (feature: FeatureSelect) => {
    return (
      <Box
        as="label"
        cursor="pointer"
        sx={{
          '.focus-visible + [data-focus]': {
            boxShadow: 'outline',
            zIndex: 1,
          },
        }}
      >
        <AccordionItem>
          <h2>
            <AccordionButton alignItems={'center'} justifyContent={'space-between'}>
              {feature.name}
              <Flex>
                <Checkbox
                  colorScheme={'gray'}
                  key={`read-checkbox-${feature.id}`}
                  isChecked={feature.read}
                  onChange={(e) => changeFeature(feature.id, 'read', e.target.checked)}
                >
                  Read
                </Checkbox>
                <Checkbox
                  ml={3}
                  colorScheme={'gray'}
                  key={`write-checkbox-${feature.id}`}
                  isChecked={feature.write}
                  onChange={(e) => changeFeature(feature.id, 'write', e.target.checked)}
                >
                  Write
                </Checkbox>
              </Flex>
            </AccordionButton>
          </h2>
        </AccordionItem>
      </Box>
    );
  };

  return (
    <Box>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <AppInput<RoleFormData>
          control={CreateControl}
          name="name"
          label="Role Name"
          placeHolder="Role Name"
          error={errors.name}
        />
        <AppInput<RoleFormData>
          mt={3}
          control={CreateControl}
          name="description"
          label="Role Description"
          placeHolder="Role Description"
          error={errors.description}
        />
        <FormLabel mt={4}>Is Portal Role</FormLabel>
        <Checkbox
          name="isPortalRole"
          defaultChecked={form?.isPortalRole}
          onChange={(e) => {
            setValue('isPortalRole', e.target.checked);
          }}
        />

        <FormLabel fontSize={'14px'} mt={6} mb={1}>
          Feature Permissions
        </FormLabel>
        <Box mt={2}>
          <Accordion allowToggle>{features?.map(renderRoleCard)}</Accordion>

          {/* {features?.map((feature) => (
            <Box key={feature.id}>
              <Checkbox
                colorScheme={'gray'}
                key={feature.id}
                isChecked={feature.selected}
                onChange={(e) => changeFeature(feature.id, e.target.checked)}
              >
                {feature.name}
              </Checkbox>
              {feature.subFeatures && feature.subFeatures.length > 0 && (
                <Stack pl={6} mt={1} spacing={1}>
                  {feature.subFeatures?.map((subFeature) => (
                    <Checkbox
                      colorScheme={'gray'}
                      key={subFeature.id}
                      isChecked={subFeature.selected}
                      onChange={(e) => changeSubFeature(subFeature, e.target.checked)}
                    >
                      {subFeature.name}
                    </Checkbox>
                  ))}
                </Stack>
              )}
            </Box>
          ))} */}
        </Box>

        <Flex flexDir={'row'} justifyContent={'flex-end'} justifyItems={'center'}>
          <AppButton variant={'solid'} mt={4} isDisabled={!isValid} onClick={() => submitForm()}>
            Save
          </AppButton>
        </Flex>
      </div>
    </Box>
  );
};

export default RoleForm;
