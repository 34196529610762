import React, { useEffect, useState } from 'react';
import { Stack } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { BaseFormProps } from './base-forms';
import {
  ProductType,
  ProductForm,
  SelectOption,
  AppInput,
  SelectListFilter,
  AppButton,
} from '@backlinkit/shared';

export type ProductsFormData = {
  id: string;
  name: string;
  description: string;
  price: number;
  isInitial: boolean;
  type: ProductType;
  linkLimit: number;
  freeCredit: number;
  priceKey: string;
  domainLimit: number;
  userLimit: number;
};

export const productsFormDefaultValues: ProductsFormData = {
  id: '',
  name: '',
  description: '',
  price: 0,
  isInitial: false,
  type: ProductType.SUBSCRIPTION,
  linkLimit: 0,
  freeCredit: 0,
  domainLimit: 0,
  userLimit: 0,
  priceKey: '',
};

const productsFormDataSchema = yup.object({
  name: yup.string().required('Field is required'),
  description: yup.string().required('Field is required'),
  price: yup.number().required('Field is required'),
  isInitial: yup.boolean(),
  type: yup.number().required('Field is required'),
  linkLimit: yup.number().required('Field is required'),
  freeCredit: yup.number().required('Field is required'),
  domainLimit: yup.number().required('Field is required'),
  userLimit: yup.number().required('Field is required'),
  priceKey: yup.string().required('Field is required'),
});

type ProductFormProps<T> = {
  form?: ProductForm;
} & BaseFormProps<T>;

const ProductsForm: React.FC<ProductFormProps<ProductsFormData>> = ({ form, onSubmit }) => {
  const productTypes = Object.values(ProductType).filter((x) => {
    return isNaN(Number(x));
  });

  const [selectOptions, setSelectOptions] = useState<SelectOption[]>([]);
  const [checkBoxValue, setCheckBoxValue] = useState<boolean>(false);

  useEffect(() => {
    if (productTypes) {
      const selectors = productTypes.map((x) => {
        return { label: x.toString(), value: x.toString() } as SelectOption;
      });
      setSelectOptions(selectors);
      console.log('selectors', selectors);
    }
  }, []);

  const {
    control: productsControl,
    formState: { isValid, errors },
    handleSubmit,
    setValue,
  } = useForm<ProductsFormData>({
    defaultValues: form || productsFormDefaultValues,
    resolver: yupResolver(productsFormDataSchema),
    mode: 'onChange',
  });

  const handleOptionSelect = (item: SelectOption) => {
    const planType = item.value;

    console.log('plan type', planType);
    if (planType === 'SUBSCRIPTION') {
      setValue('type', ProductType.SUBSCRIPTION);
    } else if (planType === 'PAYG') {
      setValue('type', ProductType.PAYG);
    } else {
      setValue('type', ProductType.CHECKERCREDITS);
    }
  };

  const handleSelect = (checked: boolean, checkboxLabel: string) => {
    if (checkboxLabel === 'Starter Plan?' && checked) {
      setCheckBoxValue(true);
    } else {
      setCheckBoxValue(false);
    }
  };

  return (
    <Stack spacing={4}>
      <AppInput<ProductsFormData>
        name="priceKey"
        control={productsControl}
        error={errors.priceKey}
        label="Price Id:"
      />
      <AppInput<ProductsFormData>
        name="name"
        control={productsControl}
        error={errors.name}
        label="Name:"
      />
      <AppInput<ProductsFormData>
        name="description"
        control={productsControl}
        error={errors.description}
        label="Description:"
      />
      <AppInput<ProductsFormData>
        name="price"
        inputType="number"
        control={productsControl}
        error={errors.price}
        label="Price:"
      />
      <SelectListFilter
        name="type"
        isInModal
        isMulti={false}
        options={selectOptions}
        placeholder="Select Plan Type"
        onSelectionChange={(value: SelectOption[]) => {
          handleOptionSelect(value[0]);
        }}
      />
      <AppInput<ProductsFormData>
        name="freeCredit"
        inputType="number"
        control={productsControl}
        error={errors.freeCredit}
        label="Free Monthly Credits"
      />
      <AppInput<ProductsFormData>
        name="linkLimit"
        inputType="number"
        control={productsControl}
        error={errors.linkLimit}
        label="Backlinks"
      />
      <AppInput<ProductsFormData>
        name="userLimit"
        inputType="number"
        control={productsControl}
        error={errors.userLimit}
        label="User Limit"
      />
      <AppInput<ProductsFormData>
        name="domainLimit"
        inputType="number"
        control={productsControl}
        error={errors.domainLimit}
        label="Domain Limit"
      />
      <AppButton w={'full'} disabled={!isValid} onClick={handleSubmit(onSubmit)} mt={6}>
        Save
      </AppButton>
    </Stack>
  );
};

export default ProductsForm;
