import { createSlice } from '@reduxjs/toolkit';
import { featureApi } from '../api/featureApi';
import { RootState } from '../store';
import { FeatureDto } from '@backlinkit/shared';

interface FeatureState {
  features?: FeatureDto[];
}

const initialState: FeatureState = {
  features: undefined,
};

export const featureSlice = createSlice({
  name: 'feature',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(featureApi.endpoints.fetchFeatures.matchFulfilled, (state, { payload }) => {
      console.log('Setting slice', payload);
      return { ...state, features: payload };
    });
  },
  reducers: {},
});

export const selectFeatures = (state: RootState) => state.feature.features;
