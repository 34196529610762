import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Flex, FlexProps, Select } from '@chakra-ui/react';

import { useEffect } from 'react';
import usePagination from '../../hooks/usePagination';
import { PageChangeEvent, Pagination } from '../../models';
import { AppColors } from '../../theme';
import { AppText } from '../app-text';
import { AppButton } from '../app-button';

export type AppPaginatorProps = {
  pagination?: Pagination;
  onPageChange: (event: PageChangeEvent) => void;
} & FlexProps;

export const AppPaginator: React.FC<AppPaginatorProps> = ({
  pagination = { page: 1, pageSize: 10, pageCount: 1, total: 0 },
  bgColor = AppColors.secondary,
  onPageChange,
  ...props
}) => {
  const {
    currentPage,
    currentPageSize,
    currentPageCount,
    goToNextPage,
    goToPreviousPage,
    goToCustomPage,
    goToFirstPage,
    goToLastPage,
    setPage,
    canGoPreviousPage,
    canGoNextPage,
    currentPageSizes,
    setPageSize,
    setNewPaginationProps,
  } = usePagination({
    page: pagination.page,
    pageSize: pagination.pageSize,
    pageCount: pagination.pageCount,
    total: pagination.total,
  });

  useEffect(() => {
    setNewPaginationProps(pagination);
  }, [pagination]);

  useEffect(() => {
    // console.log('currentPage, currentPageSize', currentPage, currentPageSize);
    // console.log('currentPage, pagiination', pagination);

    onPageChange({
      currentPage: currentPage,
      currentPageSize: currentPageSize,
      currentPageCount: currentPageCount,
    });
  }, [currentPage, currentPageSize]);

  return (
    <Flex position={'relative'} justifyContent="space-between" m={4} alignItems="center" {...props}>
      <Flex>
        <AppButton
          onClick={goToPreviousPage}
          isDisabled={!canGoPreviousPage}
          leftIcon={<ChevronLeftIcon h={6} w={6} />}
          aria-label={'Previous Page'}
          bgColor={canGoPreviousPage ? AppColors.secondary2 : 'white'}
          border={canGoPreviousPage ? 'none' : '1px solid gray.500 !important'}
          color={canGoPreviousPage ? 'white' : 'gray.500'}
          borderRadius={'full'}
          width={'100px'}
          _hover={{
            transform: 'translateY(-2px)',
            boxShadow: 'lg',
          }}
          _active={{
            bg: `${AppColors.tertiary}`,
          }}
        >
          Prev
        </AppButton>
      </Flex>

      <Flex alignItems="center" gap={4}>
        <Flex align={'center'} gap={3}>
          {currentPage - 2 > 0 && (
            <Flex
              boxSize={'35px'}
              borderRadius={'xl'}
              justify={'center'}
              align={'center'}
              fontWeight={'500'}
              border={`1px solid ${AppColors.appBorder}`}
              _hover={{
                bgColor: AppColors.secondary2,
                transform: 'translateY(-2px)',
                color: 'white',
                transition: '0.3s all',
                cursor: 'pointer',
                boxShadow: 'lg',
              }}
              onClick={() => goToCustomPage(currentPage - 2)}
            >
              {currentPage - 2}
            </Flex>
          )}
          {currentPage - 1 > 0 && (
            <Flex
              boxSize={'35px'}
              borderRadius={'xl'}
              justify={'center'}
              align={'center'}
              fontWeight={'500'}
              border={`1px solid ${AppColors.appBorder}`}
              _hover={{
                bgColor: AppColors.secondary2,
                transform: 'translateY(-2px)',
                color: 'white',
                transition: '0.3s all',
                cursor: 'pointer',
                boxShadow: 'lg',
              }}
              onClick={goToPreviousPage}
            >
              {currentPage - 1}
            </Flex>
          )}
          <Flex
            boxSize={'35px'}
            borderRadius={'xl'}
            justify={'center'}
            align={'center'}
            color={'white'}
            fontWeight={'500'}
            bgColor={AppColors.secondary2}
            border={`1px solid ${AppColors.appBorder}`}
            _hover={{
              bgColor: AppColors.secondary2,
              transform: 'translateY(-2px)',
              color: 'white',
              transition: '0.3s all',
              cursor: 'pointer',
              boxShadow: 'lg',
            }}
          >
            {currentPage}
          </Flex>
          {currentPage + 1 < currentPageCount && (
            <Flex
              boxSize={'35px'}
              borderRadius={'xl'}
              justify={'center'}
              align={'center'}
              color={'black'}
              fontWeight={'500'}
              border={`1px solid ${AppColors.appBorder}`}
              _hover={{
                bgColor: AppColors.secondary2,
                transform: 'translateY(-2px)',
                color: 'white',
                transition: '0.3s all',
                cursor: 'pointer',
                boxShadow: 'lg',
              }}
              onClick={goToNextPage}
            >
              {currentPage + 1}
            </Flex>
          )}
          {currentPage + 2 < currentPageCount && currentPage + 2 !== currentPageCount && (
            <Flex
              boxSize={'35px'}
              borderRadius={'xl'}
              justify={'center'}
              align={'center'}
              color={'black'}
              fontWeight={'500'}
              border={`1px solid ${AppColors.appBorder}`}
              _hover={{
                bgColor: AppColors.secondary2,
                transform: 'translateY(-2px)',
                color: 'white',
                transition: '0.3s all',
                cursor: 'pointer',
                boxShadow: 'lg',
              }}
              onClick={() => goToCustomPage(currentPage + 2)}
            >
              {currentPage + 2}
            </Flex>
          )}
        </Flex>

        {currentPageCount > 1 && (
          <AppText>...</AppText>
        )}

        <Flex align={'center'} gap={3}>
          <Flex align={'center'} gap={3}>
            {currentPageCount > currentPage + 2 && (
              <Flex
                boxSize={'35px'}
                borderRadius={'xl'}
                justify={'center'}
                align={'center'}
                color={'white'}
                fontWeight={'500'}
                bgColor={AppColors.secondary2}
                border={`1px solid ${AppColors.appBorder}`}
                _hover={{
                  bgColor: AppColors.secondary2,
                  transform: 'translateY(-2px)',
                  color: 'white',
                  transition: '0.3s all',
                  cursor: 'pointer',
                  boxShadow: 'lg',
                }}
              >
                {currentPageCount - 1}
              </Flex>
            )}
            {currentPageCount > currentPage + 2 && (
              <Flex
                boxSize={'35px'}
                borderRadius={'xl'}
                justify={'center'}
                align={'center'}
                color={'black'}
                fontWeight={'500'}
                _hover={{
                  bgColor: AppColors.secondary2,
                  transform: 'translateY(-2px)',
                  color: 'white',
                  transition: '0.3s all',
                  cursor: 'pointer',
                  boxShadow: 'lg',
                }}
                onClick={goToNextPage}
                border={`1px solid ${AppColors.appBorder}`}
              >
                {currentPageCount}
              </Flex>
            )}
          </Flex>
          {currentPage !== currentPageCount && (
            <Flex
              boxSize={'35px'}
              borderRadius={'xl'}
              justify={'center'}
              align={'center'}
              color={'black'}
              border={`1px solid ${AppColors.appBorder}`}
              fontWeight={'500'}
              _hover={{
                bgColor: AppColors.secondary2,
                transform: 'translateY(-2px)',
                color: 'white',
                transition: '0.3s all',
                cursor: 'pointer',
                boxShadow: 'lg',
              }}
              onClick={goToLastPage}
            >
              {currentPageCount}
            </Flex>
          )}
        </Flex>
        {/* <Select
          w={32}
          value={currentPageSize}
          onChange={(e) => {
            const value = parseInt(e.target.value);
            if (!Number.isNaN(value)) {
              setPageSize(value);
            }
          }}
        >
          {currentPageSizes.map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </Select> */}
      </Flex>

      <Flex>
        <AppButton
          onClick={goToNextPage}
          isDisabled={!canGoNextPage}
          rightIcon={<ChevronRightIcon h={6} w={6} />}
          aria-label={'Next Page'}
          bgColor={canGoNextPage ? AppColors.secondary2 : 'white'}
          border={canGoNextPage ? 'none' : '1px solid gray.500 !important'}
          color={canGoNextPage ? 'white' : 'gray.500'}
          borderRadius={'full'}
          width={'100px'}
          _hover={{
            transform: 'translateY(-2px)',
            boxShadow: 'lg',
          }}
          _active={{
            bg: `${AppColors.tertiary}`,
          }}
        >
          Next
        </AppButton>
      </Flex>
    </Flex>
  );
};
