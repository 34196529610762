import React from 'react';
import { useSelector } from 'react-redux';
import { selectDomain } from '../../../store/slices/domain.slice';
import LastModifiedCell from '../../../components/backlinks/components/cells/last-modified-cell';
import LastScrapeCell from '../../../components/backlinks/components/cells/last-scrape-cell';
import LinkTypeCell from '../../../components/backlinks/components/cells/link-type-cell';
import LinkStatusCell from '../../../components/backlinks/components/cells/link-status-cell';
import { ErrorCountCell } from '../../../components/backlinks/components/cells/error-count-cell';
import LastLiveCell from '../../../components/backlinks/components/cells/last-live-cell';
import UrlCell from '../../../components/backlinks/components/cells/url-cell';
import LandinPageCell from '../../../components/backlinks/components/cells/landing-page-cell';
import AnchorCell from '../../../components/backlinks/components/cells/anchor-cell';
import LinkNotesCell from '../../../components/backlinks/components/cells/link-notes-cell';
import GoogleIndexCell from '../../../components/backlinks/components/cells/google-index-cell';
import GoogleCacheCell from '../../../components/backlinks/components/cells/google-cache-cell';
import RelCell from '../../../components/backlinks/components/cells/rel-cell';
import IpCell from '../../../components/backlinks/components/cells/ip-cell';
import TldCell from '../../../components/backlinks/components/cells/tld-cell';
import EflCell from '../../../components/backlinks/components/cells/efl-cell';
import {
  BackLinkListItem,
  ColumnDefinitionType,
  ColumnSpecialTypes,
  AppText,
  AppDataTable,
} from '@backlinkit/shared';

type ExternalReportTableProps = {
  parentUrl: string;
  data: BackLinkListItem[];
};

const ExternalReportTable: React.FC<ExternalReportTableProps> = ({ parentUrl, data = [] }) => {
  const domain = useSelector(selectDomain);

  const backlinkColumns: ColumnDefinitionType<BackLinkListItem>[] = [
    {
      key: 'custom',
      header: 'LM',
      headerMeta: 'Last modified indicates the last date the link was amended and by which user.',
      headerSortable: false,
      render: (item) => <LastModifiedCell item={item} />,
    },
    {
      type: ColumnSpecialTypes.date,
      key: 'dateCreated',
      headerMeta: 'The date the specific link was added to be monitored.',
      header: 'Date Added',
      headerSortable: true,
    },
    {
      key: 'custom',
      header: 'PC',
      headerMeta: 'Processed Count - the amount of times the system has processed the backlink',
      headerSortable: false,
      render: (item) => (
        <LastScrapeCell updatedDate={item.lastModified} scrapeCount={item.scrapeCount} />
      ),
    },
    {
      key: 'custom',
      header: 'Type',
      headerMeta: 'We track 3 link types; text link, image and redirect.',
      headerSortable: true,
      isVisible: true,
      render: (item) => {
        return <LinkTypeCell item={item} />;
      },
    },
    {
      key: 'custom',
      headerSortable: true,
      header: 'Status',
      headerMeta: 'Indicates the HTTP status code of a link',
      render: (item) => <LinkStatusCell item={item} />,
    },
    {
      key: 'errorCount',
      header: 'Alerts',
      headerMeta: 'Shows error messages related to the specific link.',
      headerSortable: false,
      render: (item) => <ErrorCountCell item={item} />,
    },
    {
      key: 'custom',
      headerSortable: true,
      header: 'Last Live',
      headerMeta:
        'Should the specific link become inactive this field indicates when last the link was live.',
      isVisible: false,
      render: (item) => <LastLiveCell item={item} />,
    },
    {
      key: 'url',
      header: 'Url',
      headerMeta: 'Indicates the referring page your link is located on.',
      headerSortable: true,
      columnSearchable: true,
      cellDataClickable: true,
      render: (item) => <UrlCell url={item.url} />,
    },
    {
      key: 'landingPage',
      headerSortable: true,
      header: 'Landing',
      headerMeta: 'The destination URL your link points to.',
      columnSearchable: true,
      render: (item) => <LandinPageCell item={item} domainUrl={domain?.url ?? ''} />,
    },
    {
      key: 'anchor',
      headerSortable: true,
      header: 'Anchor',
      headerMeta:
        'The anchor text for text links, empty anchor for image links and the URL for redirects.',
      columnSearchable: true,
      render: (item) => <AnchorCell item={item} />,
    },
    {
      key: 'custom',
      headerSortable: false,
      header: 'Notes',
      headerMeta: 'Your specific notes related to a link',
      cellDataClickable: true,
      render: (item) => <LinkNotesCell item={item} />,
    },
    {
      key: 'custom',
      headerSortable: false,
      header: 'GI',
      headerMeta:
        'Google index indicator; Green - Page indexed, Orange - Domain indexed - Transparent - Not index',
      cellDataClickable: true,
      render: (item) => (
        <GoogleIndexCell
          url={item.url}
          hasDomainIndex={item.backlinkGoogle?.hasDomainIndex}
          hasPageIndex={item.backlinkGoogle?.hasPageIndex}
          googleCheckDate={item.backlinkGoogle?.lastGoogleCheckDate}
        />
      ),
    },
    // {
    //   key: 'custom',
    //   headerSortable: false,
    //   cellDataClickable: true,
    //   header: 'GC',
    //   headerMeta: ' Google cache; Google icon - URL is cached, Error icon - Url not cached.',
    //   render: (item) => (
    //     <GoogleCacheCell
    //       url={item.url}
    //       cacheState={item?.backlinkGoogle?.cacheState}
    //       cacheDate={item?.backlinkGoogle?.cacheCreatedDate}
    //       googleCheckDate={item.backlinkGoogle?.lastGoogleCheckDate}
    //     />
    //   ),
    // },
    {
      key: 'custom',
      headerSortable: false,
      header: 'Rel',
      headerMeta:
        'The “rel” attribute for the specific link - follow, nofollow (ugc and sponsored).',
      render: (item) => <RelCell item={item} />,
    },
    {
      key: 'custom',
      headerSortable: false,
      header: 'IP',
      headerMeta: 'The IP address retrieved from the server the website was served from.',
      render: (item) => <IpCell item={item} />,
    },
    {
      key: 'custom',
      header: 'TLD',
      headerMeta: 'Top level domain - .com, .org, .co, .co.uk (etc.)',
      headerSortable: false,
      render: (item) => <TldCell item={item} />,
    },
    {
      key: 'custom',
      headerSortable: false,
      header: 'EFL',
      headerMeta:
        'External follow links - Outbound links located on the same URL your link is located.',
      render: (item) => <EflCell parentUrl={parentUrl} item={item} />,
    },
  ];

  return (
    <AppDataTable
      data={data}
      noDataMessage={'No links added, start by adding some!'}
      columns={backlinkColumns}
      selectableRows={false}
    />
  );
};

export default ExternalReportTable;
