import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  FormLabel,
  Input,
  Table,
  TableContainer,
  Tag,
  TagLabel,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { BaseFormProps } from './base-forms';
import {
  AppInput,
  AppButton,
  Organization,
  AppColors,
  AppText,
  SelectOption,
  SelectListFilter,
} from '@backlinkit/shared';
import {
  useFetchAllOrganizationsQuery,
  useFetchOrgnizationByIdQuery,
} from '../../store/api/organizationApi';
import { SearchIcon } from '@chakra-ui/icons';
import { useFetchRolesQuery } from '../../store/api/roleApi';

export interface UserEditFormData {
  id: string;
  email: string;
  organizationId: string;
  roleId: string;
}

export const UserEditFormDataDefaultValues: UserEditFormData = {
  id: '',
  email: '',
  roleId: '',
  organizationId: '',
};

const UserEditFormDataSchema = yup.object({
  id: yup.string(),
  email: yup.string().email().required('Field is required'),
  roleId: yup.string().required('Field is required'),
});

type UserEditForm<T> = {
  form?: UserEditFormData;
} & BaseFormProps<T>;

const EditUserForm: React.FC<UserEditForm<UserEditFormData>> = ({ form, onSubmit }) => {
  const [roleList, setRoleList] = useState<SelectOption[]>([]);
  const { data: roles } = useFetchRolesQuery({});

  useEffect(() => {
    if (roles) {
      const selectors: SelectOption[] = roles.map((x) => {
        return {
          label: x.name,
          value: x.id,
        };
      });
      setRoleList(selectors);
    }
  }, [roles]);

  const {
    control: UserCreateControl,
    formState: { isValid, errors },
    setValue,
    getValues,
    trigger,
  } = useForm<UserEditFormData>({
    defaultValues: form || UserEditFormDataDefaultValues,
    resolver: yupResolver(UserEditFormDataSchema),
    mode: 'onChange',
  });

  const { data: organizations, refetch: refetchOrganizations } = useFetchAllOrganizationsQuery({
    refetchOnMountArgChange: true,
  });
  const { data: userOrg } = useFetchOrgnizationByIdQuery(form!.organizationId);

  const [searchParam, setSearchParam] = useState<string>('');
  const [organizationSearchResponse, setOrganizationSearchResponse] = useState<Organization[]>([]);
  const [selectedOrganization, setSelectedOrganization] = useState<Organization>();

  const postSearch = async (name: string) => {
    if (!name) setOrganizationSearchResponse(organizations!);

    const lowerCaseSearchParam = name.toLowerCase();
    if (!organizations) return;
    const foundItems = organizations.filter((organization) => {
      return organization.name.toLowerCase().includes(lowerCaseSearchParam);
    });
    setOrganizationSearchResponse(foundItems);
  };

  const getDefaultRoleOption = () => {
    if (roleList && form?.roleId) {
      const defaultValue = roleList.find((x) => x.value === form?.roleId);

      return defaultValue;
    }

    return undefined;
  };

  const submitForm = () => {
    const formValues = getValues();
    onSubmit(formValues);
  };

  return (
    <Flex flexDirection="column" w={'full'}>
      <form>
        <Flex flexDirection={'column'} w={'full'} gap={3}>
          <AppText fontWeight={'500'}>Search Organizations:</AppText>
          <Flex w={'full'} gap={3}>
            <Input
              w={'full'}
              placeholder="Type Here"
              onChange={(e) => {
                e.preventDefault();
                setSearchParam(e.currentTarget.value);
              }}
            />
            <AppButton
              rightIcon={<SearchIcon />}
              bgColor={AppColors.iconColorOne}
              color={'white'}
              onClick={() => {
                postSearch(searchParam);
              }}
            >
              Seach
            </AppButton>
          </Flex>
          {organizationSearchResponse && organizationSearchResponse.length > 0 && (
            <Box maxH={'200px'} overflowY={'scroll'}>
              <TableContainer>
                <Table variant={'simple'}>
                  <Thead>
                    <Tr>
                      <Th>Name</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {organizationSearchResponse.map((selectedOrg, index) => {
                      return (
                        <Tr key={index}>
                          <Td>
                            <AppText>{selectedOrg.name}</AppText>
                          </Td>
                          <Td>
                            <AppButton
                              bgColor={AppColors.iconColorOne}
                              color={'white'}
                              variant={'solid'}
                              size={'xs'}
                              borderRadius={'full'}
                              onClick={() => {
                                setSelectedOrganization(selectedOrg);
                                setValue('organizationId', selectedOrg.id);
                              }}
                            >
                              Select
                            </AppButton>
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>
          )}
          {selectedOrganization && (
            <Flex w={'full'} align={'center'} justify={'start'}>
              <AppText>Selected Organziation:</AppText>
              <Tag borderRadius={'full'} colorScheme="grey" alignItems={'center'}>
                <TagLabel>{selectedOrganization.name}</TagLabel>
              </Tag>
            </Flex>
          )}
        </Flex>
        <AppInput<UserEditFormData>
          control={UserCreateControl}
          name="email"
          label="email"
          placeHolder="email"
          error={errors.email}
        />
        <Box flex={1}>
          <FormLabel mb={2} p={0}>
            Role
          </FormLabel>
          <SelectListFilter
            name="role"
            options={roleList}
            isInModal
            defaultOption={getDefaultRoleOption()}
            onSelectionChange={(items: SelectOption[]) => {
              if (items) {
                setValue('roleId', items[0]?.value, { shouldValidate: true });
              }
            }}
            w={'100%'}
          />
        </Box>

        <Box>{JSON.stringify(getValues())}</Box>
        <AppButton w={'full'} mt={8} isDisabled={!isValid} onClick={submitForm}>
          Save
        </AppButton>
      </form>
    </Flex>
  );
};

export default EditUserForm;
