import { Flex, FlexProps, FormControl, FormLabel, Input, Textarea } from '@chakra-ui/react';
import { Control, Controller, FieldError, FieldValues, Path } from 'react-hook-form';

export type AppInputType = 'text' | 'password' | 'number' | 'file';
export type AppInputVariants = 'default' | 'solid' | 'blue' | 'grayed' | 'rounded' | 'blueSquared';

export type AppInputProps<T extends FieldValues> = FlexProps & {
  control: Control<T>;
  name: Path<T>;
  error: FieldError | undefined;
  placeHolder?: string;
  label?: string;
  textArea?: boolean;
  inputType?: AppInputType;
  labelColor?: string;
  variant?: AppInputVariants;
};

export const AppInput = <T extends FieldValues>({
  control,
  name,
  error,
  placeHolder,
  label,
  textArea = false,
  inputType = 'text',
  labelColor,
  variant = 'default',
  ...props
}: AppInputProps<T>) => {
  return (
    <Flex {...props}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <FormControl>
            {label && (
              <FormLabel fontSize={'14px'} color={labelColor}>
                {label}
              </FormLabel>
            )}

            {!textArea ? (
              <Input
                type={inputType}
                name={name}
                placeholder={placeHolder}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                ref={ref}
                h={'60px'}
                _placeholder={{
                  color: 'gray.500',
                }}
                // borderRadius={'full'}
                variant={variant}
              />
            ) : (
              <Textarea
                name={name}
                placeholder={placeHolder}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                ref={ref}
                _placeholder={{
                  color: 'gray.500',
                }}
                variant={variant}
              />
            )}
            {error && <div style={{ color: 'red' }}>{error?.message}</div>}
          </FormControl>
        )}
      />
    </Flex>
  );
};