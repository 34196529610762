import { ChevronUpIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { Th, Flex, Thead, Tr, Checkbox, Box, Tooltip } from '@chakra-ui/react';
import { useEffect } from 'react';
import { AppColors } from '../../../theme';
import { AppText } from '../../app-text/app-text';
import { TableHeaderProps, ColumnSortDir } from '../app-data-table-types';

export const TableHeader = <T extends {}>({
  columns,
  onSortByColumn,
  onSelectAll,
  selectAll,
  selectableRows,
}: TableHeaderProps<T>): JSX.Element => {
  useEffect(() => {}, [columns]);

  const headers = columns.map((column, index) => {
    const style = {
      width: column.width ?? 100,
      backgroundColor: 'rgba(3, 125, 252, 0.1)',
    };

    return (
      <Th
        key={`headCell-${index}`}
        style={style}
        onClick={() => {
          onSortByColumn && column.headerSortable && onSortByColumn(column);
        }}
        position={column.stickyColumn ? 'sticky' : 'unset'}
        top={0}
        right={0}
        height={'75px'}
        zIndex={2}
        paddingY={4}
        fontFamily={'inherit'}
      >
        <Flex flexDirection={'row'} justifyContent={'space-between'}>
          <Tooltip label={column.headerMeta ?? ''}>
            <Box>
              <AppText
                _hover={{
                  cursor: column.headerSortable ? 'pointer' : 'auto',
                }}
                fontWeight={'600'}
                fontSize={'16px'}
                color={'#222222'}
              >
                {column.header}
              </AppText>
            </Box>
          </Tooltip>
          {column.headerSortDir === ColumnSortDir.Asc && (
            <ChevronUpIcon boxSize={6} color={AppColors.secondary} />
          )}
          {column.headerSortDir === ColumnSortDir.Desc && (
            <ChevronDownIcon boxSize={6} color={AppColors.secondary} />
          )}
        </Flex>
      </Th>
    );
  });

  return (
    <Thead>
      <Tr width={'30px'}>
        {selectableRows && (
          <Th
            position={'sticky'}
            top={0}
            bg={'white'}
            width={30}
            py={6}
            zIndex={2}
            height={'75px'}
            bgColor={'rgba(3, 125, 252, 0.1)'}
          >
            <Tooltip label={'Select all rows'}>
              <Flex>
                <Checkbox
                  isChecked={selectAll}
                  onChange={(e) => {
                    onSelectAll && onSelectAll();
                  }}
                ></Checkbox>
              </Flex>
            </Tooltip>
          </Th>
        )}
        {headers}
      </Tr>
    </Thead>
  );
};
