import { AssetDto, AssetCreateResponse, AssetCreateRequest } from '@backlinkit/shared';
import { AppFetchBaseQuery } from './apiBase';
import { createApi } from '@reduxjs/toolkit/dist/query/react';

const baseUrl = '/asset';

export const assetApi = createApi({
  reducerPath: 'assetApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchAssets: build.query<AssetDto[], any>({
      query: () => ({
        url: baseUrl,
        method: 'GET',
      }),
      transformResponse: (response: AssetDto[]) => {
        return response;
      },
    }),
    fileUpload: build.mutation<AssetCreateResponse, AssetCreateRequest>({
      query: (body) => ({
        url: `${baseUrl}/upload`,
        method: 'POST',
        body: body,
      }),
      transformResponse: (response: AssetCreateResponse) => {
        return response;
      },
    }),
  }),
});

export const { useFetchAssetsQuery, useFileUploadMutation } = assetApi;
