import BaseLayout from '../../components/layouts/base-layout';
import {
  Text,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { ChevronDownIcon, DeleteIcon, SettingsIcon } from '@chakra-ui/icons';
import { useEffect } from 'react';

import { useState } from 'react';

import {
  usePanel,
  ColumnDefinitionType,
  AppButton,
  AppDataTable,
  FeatureSelect,
  RoleDto,
  RoleFeatureUpsertRequest,
  useDialog,
  AppColors,
  AppConfirm,
} from '@backlinkit/shared';
import RoleForm, { RoleFormData } from '../../components/forms/role-form';
import {
  useRoleCreateMutation,
  useRoleEditMutation,
  useRoleDeleteMutation,
  useFetchRolesQuery,
} from '../../store/api/roleApi';
import { useAppSelector } from '../../store/store';
import { selectFeatures } from '../../store/slices/feature.slice';

export const RoleManagementPage: React.FC = () => {
  const [saveRoleTrigger] = useRoleCreateMutation();
  const [editRoleTrigger] = useRoleEditMutation();
  const [deleteRoleTrigger] = useRoleDeleteMutation();
  const panel = usePanel();
  const toast = useToast();
  const features = useAppSelector(selectFeatures);
  const [filteredRoles, setFilteredRoles] = useState<RoleDto[]>([]);
  const dialog = useDialog();

  const { data: roles, refetch: refetchRoles } = useFetchRolesQuery({
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    setFilteredRoles(roles ?? []);
  }, [roles]);

  const renderDefaultTableActions = (item: RoleDto) => {
    return (
      <Menu>
        <MenuButton
          size="xs"
          bgColor={'white'}
          color={AppColors.primary}
          border={`1px solid ${AppColors.appBorder}`}
          as={Button}
          rightIcon={<ChevronDownIcon />}
          borderRadius="full"
        >
          Actions
        </MenuButton>
        <MenuList>
          <MenuItem
            icon={<SettingsIcon />}
            onClick={() => {
              handleEditDialog(item);
            }}
          >
            Edit
          </MenuItem>
          <MenuItem
            icon={<DeleteIcon />}
            onClick={() => {
              handleDeleteRoleDialog(item);
            }}
          >
            Delete
          </MenuItem>
        </MenuList>
      </Menu>
    );
  };

  const roleColumns: ColumnDefinitionType<RoleDto>[] = [
    {
      key: 'name',
      header: 'Name',
      headerSortable: true,
      columnSearchable: true,
    },
    {
      key: 'description',
      header: 'Description',
      headerSortable: true,
      columnSearchable: true,
    },
    {
      key: 'custom',
      header: 'Actions',
      render: renderDefaultTableActions,
    },
  ];

  const getSelectFeatures = (role?: RoleDto): FeatureSelect[] | undefined => {
    const selectList: FeatureSelect[] | undefined = features?.map((x) => {
      const foundRoleFeature = role?.roleFeatures?.find(
        (roleFeature) => roleFeature.featureId === x.id
      );

      return {
        id: x.id,
        name: x.name,
        read: foundRoleFeature?.roleFeaturePermission?.read || false,
        write: foundRoleFeature?.roleFeaturePermission?.write || false,
      };
    });

    return selectList;
  };

  const handleEditDialog = (role: RoleDto) => {
    const form: RoleFormData = {
      name: role.name,
      description: role.description,
      features: [],
      isPortalRole: role.isPortalRole,
    };
    panel({
      title: 'Edit Role',
      size: 'lg',
      render: (onSubmit) => {
        return (
          <RoleForm
            form={form}
            featureList={getSelectFeatures(role) || []}
            onSubmit={async (formData) => {
              onSubmit();
              await upsertRole(formData, role.id);
            }}
          ></RoleForm>
        );
      },
    });
  };

  const handleAddDialog = () => {
    const form: RoleFormData = {
      name: '',
      description: '',
      features: [],
      isPortalRole: false,
    };
    panel({
      title: 'Create Role',
      size: 'lg',
      render: (onSubmit: any) => (
        <RoleForm
          form={form}
          key={`userPanelCreate`}
          featureList={getSelectFeatures() || []}
          onSubmit={async (formData) => {
            onSubmit();
            await upsertRole(formData);
          }}
        ></RoleForm>
      ),
    });
  };

  const upsertRole = async (formData: RoleFormData, id?: string) => {
    try {
      const roleFeatures: RoleFeatureUpsertRequest[] = [];

      formData.features?.forEach((x) => {
        if (x.read || x.write) {
          roleFeatures.push({ featureId: x.id, permission: { read: x.read, write: x.write } });
        }
      });

      if (!id) {
        await saveRoleTrigger({
          name: formData.name,
          description: formData.description,
          roleFeatures: roleFeatures,
          isPortalRole: formData.isPortalRole,
        }).unwrap();
      } else {
        await editRoleTrigger({
          id: id,
          name: formData.name,
          description: formData.description,
          roleFeatures: roleFeatures,
          isPortalRole: formData.isPortalRole,
        }).unwrap();
      }

      toast({
        title: `Role ${!id ? 'added' : 'updated'}`,
        description: `We've ${!id ? 'added' : 'updated'} your role for you.`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });

      refetchRoles();
    } catch (err) {
      toast({
        title: `Role ${!id ? 'adding' : 'updating'}. error= ${err}`,
        description: `We've run into a problem ${
          !id ? 'adding' : 'updating'
        } your role, Contact support for help`,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      console.log(err);
    }
  };

  const handleDeleteRoleDialog = (role: RoleDto) => {
    dialog({
      showCancel: false,
      render: (onSubmit) => {
        return (
          <AppConfirm
            title={`You are about to remove the '${role.name}' role.`}
            message="Would you like to proceed?"
            confirmText="Yes, proceed"
            onConfirm={async () => {
              onSubmit();
              await handleDeleteRole(role);
            }}
            onCancel={() => {
              onSubmit();
            }}
          ></AppConfirm>
        );
      },
    });
  };

  const handleDeleteRole = async (role: RoleDto) => {
    try {
      await deleteRoleTrigger(role.id).unwrap();
      toast({
        title: 'Role deleted',
        description: `We've deleted the Role you selected`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });

      refetchRoles();
    } catch (error) {
      console.log('Error: ', error);
      toast({
        title: 'Role Delete Error',
        description: `We've run into a problem deleting the selected Role. Contact support for help`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    // <BaseLayout>
    //   <Flex justifyContent={'space-between'} mb={4}>
    //     <Text color={textColorPrimary} fontWeight="bold" fontSize="2xl">
    //       {'Role Management'}
    //     </Text>
    //     <Flex alignItems={'center'}>
    //       <AppButton onClick={handleAddDialog} size="sm" borderRadius={'full'}>
    //         Add Role
    //       </AppButton>
    //     </Flex>
    //   </Flex>
    //   <Flex
    //     bg={useColorModeValue('white', 'gray.800')}
    //     rounded={'2xl'}
    //     padding={4}
    //     flexDir={'column'}
    //   >
    //     <AppDataTable
    //       data={filteredRoles}
    //       noDataMessage={'No roles added, start by adding some!'}
    //       columns={roleColumns}
    //       selectableRows={false}
    //       onSearchInputChange={handleSearchChange}
    //     />
    //   </Flex>
    // </BaseLayout>

    <BaseLayout>
      <Flex flex={1} direction={'column'}>
        <Flex m={4} justifyContent={'space-between'} align={'center'} minH={'65px'}>
          <Text fontSize={'2xl'} fontWeight={'bold'} color={AppColors.primary}>
            {'Role Management'}
          </Text>
          <AppButton
            bg={'white'}
            size={'sm'}
            border={`1px solid ${AppColors.appBorder}`}
            borderRadius={'full'}
            onClick={handleAddDialog}
          >
            Add Role
          </AppButton>
        </Flex>
        <Flex
          bg={useColorModeValue('white', 'gray.800')}
          rounded={'2xl'}
          overflow={'hidden'}
          padding={'4'}
          m={4}
          flexDir={'column'}
          flex={1}
        >
          <AppDataTable
            data={filteredRoles || []}
            noDataMessage="No roles added, start by adding some!"
            columns={roleColumns}
          />
        </Flex>
      </Flex>
    </BaseLayout>
  );
};
